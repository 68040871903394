<div class="row justify-content-between" [formGroup]="data">
  @if (hasStart) {
  <div class="col-lg-6 col-xxl-4 mx-auto mb-4 mb-lg-0 px-0">
    <div class="row">
      <div class="col-12 mb-3 mb-lg-4">
        <div class="form-floating">
          <input
            id="start_at"
            type="time"
            class="form-control"
            placeholder="Hora inicio"
            formControlName="start_time"
            (ngModelChange)="alertDateStart()"
          />

          <label for="start_at" class="form-label"> Hora inicio </label>
        </div>
      </div>

      <div class="col-12">
        <p>
          Fecha de inicio:

          {{ localStart | date : "dd/MM/yyyy" }}
        </p>

        <!-- (selectedChange)="data.get('start_date')?.setValue($event)" -->
        <mat-card class="demo-inline-calendar-card border border-primary">
          <mat-calendar
            [selected]="data.get('start_date')?.value"
            [startAt]="data.get('start_date')?.value"
            (selectedChange)="handleStart($event)"
          ></mat-calendar>
        </mat-card>

        <!-- <mat-form-field class="example-full-width">
          <mat-label>Choose a date</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            (selectedChange)="handleStart($event)"
            formControlName="start_time"
          />
          <mat-hint>DD/MM/YYYY</mat-hint>

          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>

          <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field> -->
      </div>
    </div>
  </div>
  } @if (hasEnd) {
  <div class="col-lg-6 col-xxl-4 mx-auto px-0">
    <div class="row">
      <div class="col-12 mb-3 mb-lg-4">
        <div class="form-floating">
          <input
            id="end_at"
            type="time"
            class="form-control"
            placeholder="Hora fin"
            formControlName="end_time"
            (ngModelChange)="alertDateEnd()"
          />

          <label for="end_at" class="form-label"> Hora fin </label>
        </div>
      </div>

      <div class="col-12">
        <p>Fecha de fin: {{ localEnd | date : "dd/MM/yyyy" }}</p>

        <mat-card class="demo-inline-calendar-card border border-primary">
          <!-- (selectedChange)="handleEnd($event)" -->
          <!-- [selected]="data.get('end_date')?.value"
              (selected)="handleEnd($event)" -->
          <mat-calendar
            [selected]="data.get('end_date')?.value"
            [startAt]="data.get('end_date')?.value"
            (yearSelected)="handleYear($event)"
            (monthSelected)="handleYear($event)"
            (selectedChange)="handleEnd($event)"
          ></mat-calendar>
        </mat-card>
      </div>
    </div>
  </div>

  }
</div>
