import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'routePermissions',
  standalone: true,
})
export class RoutePermissionsPipe implements PipeTransform {
  // Format: admin.coupons.index
  transform(value: string): string {
    const formatted = value.replaceAll('.', '/');

    return formatted.replaceAll('index', '');
  }
}
