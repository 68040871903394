<div class="sidebar container px-0 py-3 py-lg-4">
  <div class="sidebar-header">
    <img
      [src]="event.image ? event.image : 'assets/images/logo.png'"
      class="img-fluid"
      alt="Momentos Logo"
      onerror="this.onerror=null; this.src='assets/images/logo.png'"
    />

    <p class="h5 mt-3">
      {{ event.name }}
    </p>
  </div>

  <hr class="my-4" />

  <div class="sidebar-content">
    <div class="sidebar-items">
      <ul class="list-unstyled components">
        @for (item of items; track item.id) {
        <li>
          @if (item.children.length === 0) {
          <a
            [routerLink]="[
              (eventId() !== -1 ? '/' + eventId() + '/' : '/') +
                (item.permission | routePermissions)
            ]"
            routerLinkActive="active"
          >
            <i class="fa-solid {{ item.icon }} me-2"></i>

            <span>{{ item.title }}</span>
          </a>
          } @else {
          <a
            class="item-collapsable"
            (click)="item.collapse = !item.collapse"
            [attr.aria-expanded]="!item.collapse"
            aria-controls="collapseExample"
          >
            <i class="fa-solid {{ item.icon }} me-2"></i>

            <span>{{ item.title }}</span>

            <i
              class="fa-solid fa-caret-{{
                item.collapse ? 'down' : 'up'
              }} ms-auto"
            ></i>
          </a>

          <ul
            class="collapse list-unstyled ps-3 ps-lg-4"
            #collapse="ngbCollapse"
            [(ngbCollapse)]="item.collapse"
          >
            @for (child of item.children; track child.title) {
            <li class="active">
              <a
                [routerLink]="[
                  (eventId() !== -1 ? '/' + eventId() + '/' : '/') +
                    (child.permission | routePermissions)
                ]"
                routerLinkActive="active"
              >
                <i class="fa-solid {{ child.icon }} me-2"></i> {{ child.title }}
              </a>
            </li>
            }
          </ul>
          }
        </li>
        }
      </ul>
    </div>
  </div>
</div>
