<div class="container-fluid py-3 py-lg-4 py-xl-5">
  <app-crud-form-header
    [id]="dataId()"
    [newA]="true"
    title="encuesta"
  ></app-crud-form-header>

  <form
    [formGroup]="data"
    (submit)="submit()"
    class="row justify-content-around gy-3 gy-lg-4"
  >
    <div class="col-xl-5">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Título</mat-label>
        <input matInput formControlName="name" required />

        @if (data.get('name')?.touched &&
        data.get('name')?.hasError('required')) {
        <mat-error> El título es <strong>requerido</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="col-xl-7 px-0">
      <app-image-controller
        [src]="currentTemplate.file"
        (selectFile)="selectFile($event)"
      ></app-image-controller>
    </div>

    <div class="col-12">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Descripción</mat-label>
        <textarea
          matInput
          placeholder="Descripción"
          formControlName="description"
        ></textarea>

        @if (data.get('description')?.touched &&
        data.get('description')?.hasError('required')) {
        <mat-error> La descripción es <strong>requerida</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    @if (initialized) {
    <div class="col-12 px-0">
      <app-date-controller
        [startDate]="data.get('start_at')?.value"
        [endDate]="data.get('end_at')?.value"
        (startDateChange)="data.get('start_at')?.setValue($event)"
        (endDateChange)="data.get('end_at')?.setValue($event)"
      ></app-date-controller>
    </div>
    }

    <div class="col-12">
      <mat-checkbox formControlName="active">Activo</mat-checkbox>
    </div>

    <div class="col-lg">
      <h5>Cuestionario</h5>
    </div>

    <div class="col-lg-auto">
      <button type="button" class="btn btn-primary" (click)="addQuestion()">
        <i class="fa-solid fa-plus me-2"></i> Añadir pregunta
      </button>
    </div>

    <div class="col-12 pb-4 px-0">
      <mat-list
        class="questions-list border border-primary rounded-1 shadow-sm"
        cdkDropList
        (cdkDropListDropped)="drop($event)"
      >
        @for (question of questions; track $index) {
        <!-- cdkDragLockAxis="y" -->
        <mat-list-item cdkDrag class="qs-container">
          <div class="row">
            <div class="col-2 col-lg-auto pe-0">
              <div class="buttons-action">
                <button
                  class="btn btn-light border-dark d-none d-lg-inline"
                  cdkDragHandle
                >
                  <i class="fa-solid fa-bars"></i>
                </button>

                <div
                  class="btn-group-vertical d-lg-none"
                  role="group"
                  aria-label="Vertical button group"
                >
                  @if ($index > 0) {
                  <button
                    type="button"
                    class="btn btn-light border-dark"
                    (click)="raiseQs($index)"
                  >
                    <i class="fa-solid fa-arrow-up"></i>
                  </button>
                  } @if ($index < questions.length - 1 ) {
                  <button
                    type="button"
                    class="btn btn-light border-dark"
                    (click)="lowerQs($index)"
                  >
                    <i class="fa-solid fa-arrow-down"></i>
                  </button>
                  }
                </div>

                <button
                  type="button"
                  class="btn btn-danger mt-3"
                  (click)="deleteQuestion($index)"
                >
                  <i class="fa-solid fa-trash"></i>
                </button>
              </div>
            </div>

            <div class="col-10 col-lg py-2 px-0">
              <app-poll-question
                [types]="types"
                [data]="question"
              ></app-poll-question>
            </div>
          </div>
        </mat-list-item>
        }
      </mat-list>
    </div>

    <app-alert [content]="alertData"></app-alert>

    <div class="w-100 mt-0"></div>

    <div class="col-12 px-0">
      <app-crud-form-footer
        [id]="dataId()"
        [created]="created"
        [loading]="loadingService.getLoading()"
        (cancel)="cancel()"
      ></app-crud-form-footer>
    </div>
  </form>
</div>
