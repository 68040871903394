<mat-drawer-container class="app-container">
  <mat-drawer
    #drawer
    mode="side"
    class="sidebar-container bg-primary"
    [mode]="isMobile ? 'over' : 'side'"
    [opened]="!isMobile"
  >
    @defer(when eventId() !== -1 && event.id !== -1) {
    <app-sidebar [event]="event" [eventId]="eventId" [items]="menuItems" />
    }
  </mat-drawer>

  <mat-drawer-content>
    <div class="app">
      @defer(when initialized) {
      <app-header
        [canShow]="canShow()"
        [canCreate]="canCreate()"
        [data]="data"
        [eventId]="eventId"
        (onOpen)="drawer.open()"
        (onPageChange)="pagination($event)"
      />
      }

      <main [ngClass]="{ loading: loadingService.getLoading() }">
        <router-outlet></router-outlet>
      </main>

      @if (loadingService.getLoading()) {
      <div class="spinner-overlay">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      }

      <app-footer />
    </div>
  </mat-drawer-content>
</mat-drawer-container>
