<div class="crud-page">
  <div class="crud-content">
    <div class="container-fluid py-3 py-lg-4">
      <app-crud-header
        [title]="title"
        [createRoute]="crudRoute()"
      ></app-crud-header>

      <div class="row justify-content-evenly">
        @if (data.rows.length > 0) {
        <div class="col-12 px-0 mb-3">
          <app-search-bar
            [filters]="filters"
            (onSearch)="handleSearch($event)"
          ></app-search-bar>
        </div>
        }

        <app-alert [content]="alertData"></app-alert>

        <div class="table-responsive crud-table">
          <table class="table table-bordered border-primary">
            <thead class="table-crud-header">
              <tr>
                @for (col of data.cols; track $index) {
                <th scope="col">
                  @if (col.sort) {
                  <button
                    class="btn btn-link text-light text-decoration-none"
                    (click)="sort(col)"
                  >
                    {{ col.label }}

                    @if (data.order[0] !== col.field) {
                    <i class="fa-solid fa-sort"></i>
                    } @else {
                    <i
                      class="fa-solid"
                      [ngClass]="
                        data.order[1] === 'asc' ? 'fa-sort-up' : 'fa-sort-down'
                      "
                    ></i>
                    }
                  </button>
                  } @else {
                  <strong class="text-light">{{ col.label }}</strong>
                  }
                </th>
                } @if (canShow() || canEdit() || canDelete()) {
                <th class="td-small" scope="col"></th>
                }
              </tr>
            </thead>
            <tbody class="table-group-divider">
              @for (country of data.rows; track $index) {
              <tr>
                @for (col of data.cols; track $index) { @if (
                col.field.includes('_at')) {
                <td class="td-full">
                  {{ country[col.field] | date : "dd/MM/yyyy hh:mm" }}
                </td>
                } @else {
                <td class="td-full">{{ country[col.field] }}</td>

                } } @if (canShow() || canEdit() || canDelete()) {
                <td class="td-small">
                  <div
                    class="btn-group"
                    role="group"
                    aria-label="Basic mixed styles example"
                  >
                    @if (canShow()) {
                    <button
                      class="btn btn-outline-dark"
                      (click)="showModal(country); (false)"
                    >
                      <i class="fas fa-eye"></i>
                    </button>
                    } @if (canEdit()) {
                    <a
                      class="btn btn-outline-dark"
                      [routerLink]="[crudRoute() + '/edit/' + country.id]"
                    >
                      <i class="fas fa-edit"></i>
                    </a>
                    } @if (canDelete()) {
                    <button
                      class="btn btn-danger btn-destroy"
                      (click)="delete(country.id)"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                    }
                  </div>
                </td>
                }
              </tr>
              } @empty {
              <tr>
                <th>No hay elementos</th>
                @for (col of data.cols; track $index) { @if ($index > 0) {
                <th></th>
                } } @if (canShow() || canEdit() || canDelete()) {
                <th></th>
                }
              </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>

    @if (pagination()) {
    <div class="crud-footer mb-auto">
      <mat-paginator
        [pageSizeOptions]="[10, 25, 50, 100]"
        [length]="data.total"
        [pageSize]="data.limit"
        aria-label="Selecciona pagina de placas"
        (page)="handlePage($event)"
      ></mat-paginator>
    </div>
    }
  </div>
</div>
