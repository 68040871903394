import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { Observable } from 'rxjs';

import { AlertComponent } from '@shared/components/alert/alert.component';
import { AutofocusDirective } from '@shared/directives/autofocus.directive';
import { editorConfig } from '@shared/config/editor-config';

import {
  CrudFormComponent,
  CrudFormFooterComponent,
  CrudFormHeaderComponent,
  DateControllerComponent,
  ImageControllerComponent,
} from '@admin/components';

@Component({
  selector: 'app-articles-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    AngularEditorModule,

    AutofocusDirective,

    AlertComponent,
    CrudFormHeaderComponent,
    CrudFormFooterComponent,
    DateControllerComponent,
    ImageControllerComponent,
  ],
  templateUrl: './articles-form.component.html',
  styleUrl: './articles-form.component.scss',
})
export class ArticlesFormComponent extends CrudFormComponent {
  override services: Observable<any>[] = [
    this.api.call('admin/catalogs/articles/types'),
    this.api.call('admin/catalogs/articles/resource-types'),
  ];

  resources: string[] = [];
  types: string[] = [];
  editorConfig = editorConfig;

  override data = this.formBuilder.group({
    name: ['', [Validators.required]],
    type: ['', [Validators.required]],
    resource_type: [null, [Validators.required]],
    resource_content: [null, [Validators.required]],
    active: [true, Validators.required],
    start_at: [''],
    end_at: [''],
    image: ['', [Validators.required]],
    tags: [[], Validators.required],
  });

  override handleLoadResponse(responses: any[]): void {
    if (responses[0] && responses[0].status === 200)
      this.types = responses[0].data;

    if (responses[1] && responses[1].status === 200)
      this.resources = responses[1].data;

    if (
      this.dataId() !== -1 &&
      responses[responses.length - 1] &&
      responses[responses.length - 1].status === 200
    ) {
      const responseData = responses[responses.length - 1].data;

      this.data.patchValue(responseData);

      if (
        this.data.get('image')?.value &&
        typeof this.data.get('image')?.value === 'string'
      ) {
        this.currentTemplate.file = String(this.data.get('image')?.value ?? '');
      }
    }
  }

  override getParams() {
    const params: any = this.data.value;
    params['active'] = Number(params['active']);

    if (this.eventId() !== -1) {
      params['event_id'] = this.eventId();
    }

    return params;
  }

  override handleFile() {
    if (this.currentTemplate.file) {
      this.data.get('image')?.setValue(this.currentTemplate.file);
    }
  }
}
