<div class="card h-100">
  <div class="card-header px-0 px-lg-2 py-3">
    <div class="row align-items-center">
      <div class="col">
        <p class="h5 mb-0">Fecha {{ index }}</p>
      </div>

      <div class="col-lg-auto text-end mt-3 mt-lg-0">
        <button
          type="button"
          class="btn btn-danger"
          (click)="delete.emit(index)"
          aria-label="Eliminar fecha"
        >
          <span class="d-lg-none me-2"> Eliminar </span>
          <i class="fa-solid fa-trash"></i>
        </button>
      </div>
    </div>
  </div>

  <div
    class="card-body px-0 px-lg-2 my-2 my-lg-3"
  >
    <app-date-controller
      [startDate]="date.start_at"
      [endDate]="date.end_at"
      (startDateChange)="date.start_at = $event"
      (endDateChange)="date.end_at = $event"
    ></app-date-controller>
  </div>
</div>
