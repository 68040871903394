<div class="crud-page">
  <div class="crud-content">
    <div class="container-fluid py-3 py-lg-4">
      <div class="row justify-content-evenly">
        <app-crud-header
          title="Eventos"
          [createRoute]="crudRoute()"
        ></app-crud-header>

        @if (data.rows.length > 0) {
        <div class="col-12 px-0 mb-3 mb-lg-4">
          <app-search-bar
            [filters]="filters"
            (onSearch)="handleSearch($event)"
          ></app-search-bar>
        </div>
        }

        <app-alert [content]="alertData"></app-alert>

        @for (event of data.rows; track $index) {
        <div class="col-sm-6 col-lg-4 col-xxl-3 mb-3 mb-lg-4">
          <div class="card shadow">
            <!-- [ngStyle]="{ 'background-color': event.theme.color.primary }" -->
            <div class="card-header text-bg-primary">
              <div class="d-flex w-100 align-items-center">
                {{ event.name }}

                <button
                  class="ms-auto"
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  aria-label="Example icon-button with a menu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #menu="matMenu" xPosition="before">
                  <button
                    mat-menu-item
                    class="text-primary"
                    (click)="download(qr)"
                  >
                    <mat-icon class="text-primary">download</mat-icon>
                    <span>Descargar</span>
                  </button>

                  <button
                    mat-menu-item
                    [routerLink]="[crudRoute() + '/edit/' + event.id]"
                    routerLinkActive="router-link-active"
                  >
                    <mat-icon>edit</mat-icon>
                    <span>Editar</span>
                  </button>

                  <button
                    mat-menu-item
                    [routerLink]="[crudRoute() + '/edit/' + event.id]"
                  >
                    <mat-icon>block</mat-icon>
                    <span>Desactivar</span>
                  </button>

                  <button
                    mat-menu-item
                    class="text-danger"
                    (click)="delete(event.id)"
                  >
                    <mat-icon class="text-danger">delete</mat-icon>
                    <span>Borrar</span>
                  </button>
                </mat-menu>
              </div>
            </div>

            <div class="card-body px-0">
              <!-- <img [src]="event.image" class="img-fluid" alt="Imagen evento" /> -->
              <div class="row">
                <div #qr class="col-12 mb-3">
                  <qrcode
                    [qrdata]="event.uuid"
                    [margin]="0"
                    [width]="1000"
                    [errorCorrectionLevel]="'M'"
                  ></qrcode>
                </div>

                <div class="col-12 mb-3">
                  <div class="row align-items-center">
                    <div class="col-auto px-0">
                      <mat-icon class="text-primary">calendar_month</mat-icon>
                    </div>

                    <div class="col">
                      {{ event.start_at | date : "dd/MM/yyyy" }}
                      -
                      {{ event.end_at | date : "dd/MM/yyyy" }}
                    </div>

                    <div class="w-100 mb-3"></div>

                    <div class="col-auto px-0">
                      <mat-icon class="text-primary">location_on</mat-icon>
                    </div>

                    <div class="col">
                      {{ event.location }}
                    </div>
                  </div>
                </div>

                <div class="col-12 text-end mb-3 mb-lg-4">
                  <button
                    mat-mini-fab
                    (click)="download(qr)"
                    color="primary"
                    aria-label="Example icon button with a menu icon"
                  >
                    <mat-icon>download</mat-icon>
                  </button>
                </div>

                <div class="col-12">
                  <div class="row border border-dark">
                    @if( event.theme?.color?.primary ) {
                    <div
                      class="col"
                      [style.background-color]="event.theme?.color?.primary"
                      [style.height.px]="30"
                      [title]="event.theme?.color?.primary"
                    ></div>
                    } @if( event.theme?.color?.secondary ) {
                    <div
                      class="col"
                      [style.background-color]="event.theme?.color?.secondary"
                      [style.height.px]="30"
                      [title]="event.theme?.color?.secondary"
                    ></div>
                    } @if( event.theme?.color?.tertiary ) {
                    <div
                      class="col"
                      [style.background-color]="event.theme?.color?.tertiary"
                      [style.height.px]="30"
                      [title]="event.theme?.color?.tertiary"
                    ></div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        } @empty {
        <p class="h4 text-secondary">No hay elementos</p>
        }
      </div>
    </div>
  </div>

  @if (pagination()) {
  <div class="crud-footer mb-auto">
    <mat-paginator
      [pageSizeOptions]="[10, 25, 50, 100]"
      [length]="data.total"
      [pageSize]="data.limit"
      aria-label="Selecciona pagina de placas"
      (page)="handlePage($event)"
    ></mat-paginator>
  </div>
  }
</div>
