import { Main } from '@shared/models/Main.model';

export class Coupon extends Main {
  id: number = -1;
  name: string = '';
  description: string = '';
  amount: string = '';
  type: string = '';
  discount: string = '';
  image: string = '';
  start_at: string | null = null;
  end_at: string | null = null;
  event_id: number = 0;
  enterprise_id: number = 0;
  active: number = 0;
  created_at: string | null = null;
  updated_at: string | null = null;
  deleted_at: string | null = null;
  currency: string = '';

  override setData(data: any) {
    for (const key in this) {
      if (data[key] !== undefined) {
        this[key] = data[key];
      }
    }
  }
}
