import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateControllerComponent } from '../date-controller/date-controller.component';
import { CommonModule } from '@angular/common';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { DateModel } from '@admin/models/Date.model';

@Component({
  selector: 'app-schedule-date-controller',
  standalone: true,
  imports: [CommonModule, DateControllerComponent, NgbCollapseModule],
  templateUrl: './schedule-date-controller.component.html',
  styleUrl: './schedule-date-controller.component.scss',
})
export class ScheduleDateControllerComponent {
  @Input() index: number = 0;
  @Input() last: boolean = false;
  @Input() date: DateModel = new DateModel();
  @Output() delete: EventEmitter<number> = new EventEmitter();
}
