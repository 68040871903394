<div class="container-fluid py-3 py-lg-4">
  <app-crud-form-header
    [id]="dataId()"
    title="notificación"
    [newA]="true"
  ></app-crud-form-header>

  <form
    [formGroup]="data"
    (ngSubmit)="submit()"
    class="row justify-content-around gy-3 gy-lg-4"
  >
    <div class="col-12">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Título de la Notificación</mat-label>
        <input matInput formControlName="name" required />

        @if (data.get('name')?.touched &&
        data.get('name')?.hasError('required')) {
        <mat-error> El título es <strong>requerido</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="col-12">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Descripción</mat-label>
        <textarea
          matInput
          placeholder="Descripción"
          formControlName="body"
        ></textarea>

        @if (data.get('body')?.touched &&
        data.get('body')?.hasError('required')) {
        <mat-error> La descripción es <strong>requerida</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="col-12">
      <mat-checkbox formControlName="active">Activo</mat-checkbox>
    </div>

    @if (initialized) {
    <div class="col-12 px-0">
      <app-date-controller
        [hasEnd]="false"
        [startDate]="data.get('start_at')?.value"
        (startDateChange)="data.get('start_at')?.setValue($event)"
      ></app-date-controller>
    </div>
    }

    <app-alert [content]="alertData"></app-alert>

    <div class="w-100 mt-0"></div>

    <div class="col-12 px-0">
      <app-crud-form-footer
        [id]="dataId()"
        [created]="created"
        [loading]="loadingService.getLoading()"
        (cancel)="cancel()"
      ></app-crud-form-footer>
    </div>
  </form>
</div>
