import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { FormComponent } from '@shared/components';
import { AlertComponent } from '@shared/components/alert/alert.component';
import { passwordValidator } from '@shared/validators/password.validator';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatInputModule, AlertComponent],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent extends FormComponent {
  currentTemplate = {
    name: '',
    file: '',
  };

  override data = this.formBuilder.group({
    name: ['', [Validators.required]],
    lastname: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    curp: ['', [Validators.required]],
    avatar: '',

    password: [''],
    confirm: [''],
  });
  override endpoint: string = 'api/app/profile';
  override method: string = 'put';

  ngOnInit(): void {
    this.startLoading();

    this.api.call('api/app/profile').subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.data.patchValue(response.data);
        }

        this.stopLoading();
      },
      error: (error) => {
        console.error(error);
        this.stopLoading();
      },
    });
  }

  override getParams() {
    const params: any = this.data.value;

    delete params['confirm'];

    if (!this.data.get('password')?.value) {
      delete params['password'];
    }

    return params;
  }

  override isValid(): boolean {
    const passwordControl = this.data.get('password');
    const confirmControl = this.data.get('confirm');

    if (passwordControl?.value) {
      passwordControl.setValidators([
        Validators.minLength(8),
        passwordValidator(),
      ]);

      confirmControl?.setValidators([
        Validators.minLength(8),
        passwordValidator(),
      ]);

      passwordControl.updateValueAndValidity();
      confirmControl?.updateValueAndValidity();
    }

    return this.data.valid;
  }

  override success(response: any): void {
    this.handleAlert('Cambios guardados correctamnete', 'success');
    this.stopLoading();
  }

  onCURPInput(event: any) {
    const inputElement = event.target as HTMLInputElement;
    const uppercasedValue = inputElement.value.toUpperCase();
    this.data.get('curp')!.setValue(uppercasedValue);
  }

  selectFile(file: Event) {
    if (this.loadingService.getLoading()) return;

    this.startLoading();

    const target = file.target as HTMLInputElement;
    let template = { name: '', file: '' };
    const uploadedFile = (target.files as FileList)[0];

    if (target) {
      if (!uploadedFile.name) {
        this.stopLoading();
        return;
      }

      const maxSizeInBytes = 3 * 1024 * 1024;
      if (uploadedFile.size > maxSizeInBytes) {
        this.handleAlert('El archivo excede el tamaño permitido de 3 MB');

        this.stopLoading();
        return;
      }

      template.name = uploadedFile.name;

      let fileReader = new FileReader();

      if (target.files && target.files.length > 0) {
        let file = target.files[0];
        fileReader.readAsDataURL(file);

        fileReader.onload = (event: any) => {
          template.file = event.target.result;

          this.data.get('avatar')!.setValue(template.file);

          this.currentTemplate.name = template.name;
          this.currentTemplate.file = template.file;
        };
      }
    }

    this.stopLoading();
  }
}
