<div class="container-fluid py-3 py-lg-4 py-xl-5">
  <app-crud-form-header
    [id]="dataId()"
    [newA]="true"
    title="empresa"
  ></app-crud-form-header>

  <form
    [formGroup]="data"
    (submit)="submit()"
    class="row justify-content-around gy-3 gy-lg-4"
  >
    <div class="col-12">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="name" required />

        @if (data.get('name')?.touched &&
        data.get('name')?.hasError('required')) {
        <mat-error> El Nombre es <strong>requerido</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="col-12 px-0">
      <app-image-controller
        [src]="currentTemplate.file"
        (selectFile)="selectFile($event)"
      ></app-image-controller>
    </div>

    <div class="col-lg-6">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Dirección</mat-label>
        <input matInput formControlName="address" required />

        @if (data.get('address')?.touched &&
        data.get('address')?.hasError('required')) {
        <mat-error> La Dirección es <strong>requerida</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="col-lg-6">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Teléfono</mat-label>
        <input matInput formControlName="phone" required />

        @if (data.get('phone')?.touched &&
        data.get('phone')?.hasError('required')) {
        <mat-error> El Teléfono es <strong>requerido</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    <app-alert [content]="alertData"></app-alert>

    <div class="w-100 mt-0"></div>

    <div class="col-12 px-0">
      <app-crud-form-footer
        [id]="dataId()"
        [created]="created"
        [loading]="loadingService.getLoading()"
        (cancel)="cancel()"
      ></app-crud-form-footer>
    </div>
  </form>
</div>
