<div class="container-fluid px-0 px-lg-3 py-3 py-lg-4 py-xl-5">
  <app-crud-form-header [id]="dataId()" title="evento"></app-crud-form-header>

  <form
    [formGroup]="data"
    (submit)="submit()"
    class="row justify-content-around gy-4"
  >
    <div class="col-12">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Título</mat-label>
        <input matInput formControlName="name" required />

        @if (data.get('name')?.touched &&
        data.get('name')?.hasError('required')) {
        <mat-error> El título es <strong>requerido</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="col-12">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Descripción</mat-label>
        <textarea
          matInput
          placeholder="Descripción"
          formControlName="description"
        ></textarea>

        @if (data.get('description')?.touched &&
        data.get('description')?.hasError('required')) {
        <mat-error> La descripción es <strong>requerida</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="col-12 px-0">
      <app-image-controller
        [src]="currentTemplate.file"
        (selectFile)="selectFile($event)"
      ></app-image-controller>
    </div>

    <div class="col-12 px-0">
      <div class="row">
        <div class="col-12 mb-3"><hr /></div>

        <div class="col-12 mb-4">
          <p class="mb-0">Archivos PDF</p>
        </div>

        <div class="col-xl-7 mb-4 mb-lg-0">
          <input
            id="pdf-selector"
            placeholder="avatar"
            class="form-control"
            type="file"
            (change)="selectDocument($event)"
            accept="application/pdf"
          />
        </div>

        <div class="col-xl-5 px-0">
          <div class="row">
            @for (pdfFile of pdfFiles; track $index) {
            <div class="col-12 mb-3">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Archivo PDF"
                  aria-label="Recipient's username"
                  aria-describedby="current-file"
                  [value]="pdfFile.name"
                  readonly
                />

                <button
                  class="btn btn-danger"
                  type="button"
                  id="current-file"
                  (click)="deletePdf($index)"
                >
                  Eliminar
                </button>
              </div>
            </div>
            }
          </div>
        </div>

        <div class="col-12 mt-3"><hr /></div>
      </div>
    </div>

    <div class="col-12">
      <mat-checkbox formControlName="active">Activo</mat-checkbox>
    </div>

    @if(!loadingService.loader() ) {
    <div class="col-12 px-0 mb-3">
      <div class="row">
        <div class="col-12 mt-4 mb-3">
          <h2 class="h4">Fechas</h2>
        </div>

        <div class="col-12 px-0">
          <div class="row">
            @for (date of dates; track $index) {
            <div class="col-12 mb-4">
              <app-schedule-date-controller
                [index]="$index + 1"
                [date]="date"
                [last]="dates.length - 1 === $index"
                (delete)="deleteDate($event)"
              ></app-schedule-date-controller>
            </div>
            }
          </div>
        </div>

        <div class="col-12 text-end mt-3">
          <button
            type="button"
            mat-raised-button
            color="primary"
            (click)="newDate()"
          >
            <mat-icon>add</mat-icon>

            Nueva
          </button>
        </div>
      </div>
    </div>
    }

    <div class="col-12 mb-3 mb-lg-4">
      <h3 class="h4">Expositores</h3>

      <ng-select
        id="expositor"
        appearance="outline"
        [clearable]="false"
        [items]="speakers"
        [loading]="loadingService.loader()"
        [multiple]="true"
        placeholder="Selecciona el expositor"
        bindLabel="name"
        formControlName="speakers"
        bindValue="id"
        loadingText="Cargando..."
      >
      </ng-select>
    </div>

    <div class="w-100"></div>

    <app-alert [content]="alertData"></app-alert>

    <div class="w-100 mt-0"></div>

    <div class="col-12 px-0">
      <app-crud-form-footer
        [id]="dataId()"
        [created]="created"
        [loading]="loadingService.getLoading()"
        (cancel)="cancel()"
      ></app-crud-form-footer>
    </div>
  </form>
</div>
