import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import {
  FullCalendarComponent,
  FullCalendarModule,
} from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';

@Component({
  selector: 'app-schedule-calendar',
  standalone: true,
  imports: [CommonModule, FullCalendarModule],
  templateUrl: './schedule-calendar.component.html',
  styleUrl: './schedule-calendar.component.scss',
})
export class ScheduleCalendarComponent {
  @Input() crudRoute: string = '';
  @Input() events: any[] = [];

  @ViewChild(FullCalendarComponent) calendarComponent!: FullCalendarComponent;
  calendarOptions: CalendarOptions = {};

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.calendarOptions = {
      plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'timeGridDay,timeGridWeek,dayGridMonth',
      },
      initialView: 'timeGridWeek',
      initialDate: this.events[0].date,
      events: [...this.events],
      views: {
        timeGrid: {
          slotLabelFormat: {
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: 'short',
          },
        },
      },
      locales: [esLocale],
      eventClick: (info) => {
        this.router.navigate([this.crudRoute + '/edit/' + info.event.id]);
      },
    };
  }

  handleDateClick(arg: any) {
    alert('date click! ' + arg.dateStr);
  }

  changeDate(date: string) {
    this.calendarComponent.getApi().gotoDate(date);
  }
}
