import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { forkJoin } from 'rxjs';

import { AlertComponent } from '@shared/components/alert/alert.component';
import { AutofocusDirective } from '@shared/directives/autofocus.directive';

import { CrudFormFooterComponent } from '@admin/components/crud-form-footer/crud-form-footer.component';
import { CrudFormHeaderComponent } from '@admin/components/crud-form-header/crud-form-header.component';
import { CrudFormComponent } from '@admin/components/crud-form/crud-form.component';
import { DateControllerComponent } from '@admin/components/date-controller/date-controller.component';
import { ImageControllerComponent } from '@admin/components/image-controller/image-controller.component';

@Component({
  selector: 'app-speakers-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatInputModule,

    AutofocusDirective,

    AlertComponent,
    CrudFormHeaderComponent,
    CrudFormFooterComponent,
    DateControllerComponent,
    ImageControllerComponent,
  ],
  templateUrl: './speakers-form.component.html',
  styleUrl: './speakers-form.component.scss',
})
export class SpeakersFormComponent extends CrudFormComponent {
  override data = this.formBuilder.group({
    name: ['', [Validators.required]],
    description: ['', [Validators.required]],
    email: ['', [Validators.required]],
    phone: ['', [Validators.required]],
    image: ['', [Validators.required]],
    location: [''],
    active: [false],
  });

  override handleLoadResponse(responses: any[]): void {
    if (this.dataId() !== -1 && responses[0] && responses[0].status === 200) {
      const responseData = responses[0].data;

      this.data.patchValue(responseData);

      if (
        this.data.get('image')?.value &&
        typeof this.data.get('image')?.value === 'string'
      ) {
        this.currentTemplate.file = String(this.data.get('image')?.value ?? '');
      }

      if (responseData['image']) {
        this.currentTemplate.file = responseData['image'];
      }

      if (
        responseData['documents'] &&
        Array.isArray(responseData['documents'])
      ) {
        this.pdfFiles = responseData['documents'];
      }
    }
  }

  override submit() {
    if (this.loadingService.getLoading() || !this.isValid()) return;

    this.startLoading();

    const params = this.getParams();
    const services = [this.api.call(this.endpoint, this.method, params)];

    console.log(this.pdfFiles);

    this.pdfFiles.forEach((pdfFile) => {
      services.push(
        this.api.call(`${this.endpoint}/document`, 'post', {
          name: pdfFile.name,
          content: pdfFile.file,
        })
      );
    });

    forkJoin(services).subscribe({
      next: (responses) => {
        if (responses[0].status === 200) {
          this.success(responses[0]);
        } else {
          this.failed(responses[0].message);
        }

        const fileResponses = responses.slice(1);
        let errors: string[] = [];

        fileResponses.forEach((response) => {
          if (response.status !== 200) {
            errors.push(
              response.message.join(', ') || 'Error al subir el archivo'
            );
          }
        });
      },
      error: (errors) => {
        this.failed(errors);
      },
    });
  }

  override handleFile() {
    if (this.currentTemplate.file) {
      this.data.get('image')?.setValue(this.currentTemplate.file);
    }
  }

  override getParams() {
    const params: any = this.data.value;
    params.active = Number(params.active);

    if (this.eventId() !== -1) {
      params['event_id'] = this.eventId();
    }

    return params;
  }
}
