import { Routes } from '@angular/router';

import { environment } from '@environments';

import { adminRoutes } from './admin/admin.routes';
import { authRoutes } from './auth/auth.routes';

import { privateGuard, privateGuardChild } from '@shared/guards/private.guard';
import { publicGuard, publicGuardChild } from '@shared/guards/public.guard';

import { AdminComponent } from '@admin/admin.component';

import {
  EventsComponent,
  EventsFormComponent,
  HomeComponent,
  PermissionsComponent,
  PermissionsFormComponent,
  PrivacyComponent,
  RolesComponent,
  RolesFormComponent,
  TermsComponent,
} from '@admin/pages';

import { adminGuard } from '@shared/guards/admin.guard';

export const routes: Routes = [
  {
    path: 'auth',
    children: [...authRoutes],
    canActivate: [publicGuard],
    canActivateChild: [publicGuardChild],
  },
  // path: 'admin',
  {
    path: 'admin',
    component: AdminComponent,
    title: `Admin - ${environment.title}`,
    children: [
      {
        path: 'aviso-de-privacidad',
        component: PrivacyComponent,
        title: `Aviso de Privacidad - ${environment.title}`,
      },
      {
        path: 'events',
        component: EventsComponent,
        title: `Eventos - ${environment.title}`,
        canActivate: [adminGuard],
        data: {
          requiredRoles: ['admin.events.index'],
        },
      },
      {
        path: 'events/create',
        component: EventsFormComponent,
        title: `Eventos - ${environment.title}`,
        // canActivate: [adminGuard],
        data: {
          requiredRoles: ['admin.events.index'],
        },
      },
      {
        path: 'events/edit/:id',
        component: EventsFormComponent,
        title: `Eventos - ${environment.title}`,
        canActivate: [adminGuard],
        data: {
          requiredRoles: ['admin.events.index'],
        },
      },
      {
        path: 'permissions',
        component: PermissionsComponent,
        title: `Permisos - ${environment.title}`,
        canActivate: [adminGuard],
        data: {
          requiredRoles: ['admin.permissions.index'],
        },
      },
      {
        path: 'permissions/create',
        component: PermissionsFormComponent,
        title: `Permisos - ${environment.title}`,
        canActivate: [adminGuard],
        data: {
          requiredRoles: ['admin.permissions.index'],
        },
      },
      {
        path: 'permissions/edit/:id',
        component: PermissionsFormComponent,
        title: `Permisos - ${environment.title}`,
        canActivate: [adminGuard],
        data: {
          requiredRoles: ['admin.permissions.index'],
        },
      },
      {
        path: 'roles',
        component: RolesComponent,
        title: `Roles - ${environment.title}`,
        canActivate: [adminGuard],
        data: {
          requiredRoles: ['admin.roles.index'],
        },
      },
      {
        path: 'roles/create',
        component: RolesFormComponent,
        title: `Roles - ${environment.title}`,
        canActivate: [adminGuard],
        data: {
          requiredRoles: ['admin.roles.index'],
        },
      },
      {
        path: 'roles/edit/:id',
        component: RolesFormComponent,
        title: `Roles - ${environment.title}`,
        canActivate: [adminGuard],
        data: {
          requiredRoles: ['admin.roles.index'],
        },
      },
      {
        path: 'terminos-y-condiciones',
        component: TermsComponent,
        title: `Términos y Condiciones - ${environment.title}`,
      },
      {
        path: 'home',
        component: HomeComponent,
        title: `Home - ${environment.title}`,
      },
      {
        path: '**',
        redirectTo: 'home',
      },
    ],
    canActivate: [privateGuard],
    canActivateChild: [privateGuardChild],
  },
  {
    path: ':eventid/admin',
    component: AdminComponent,
    title: `Admin - ${environment.title}`,
    children: [...adminRoutes],
    canActivate: [privateGuard],
    canActivateChild: [privateGuardChild],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth',
  },
];
