<div class="container-fluid py-3 py-lg-4 py-xl-5">
  <app-crud-form-header [id]="dataId()" title="cupón"></app-crud-form-header>

  <form
    [formGroup]="data"
    (submit)="submit()"
    class="row justify-content-around gy-3 gy-lg-4"
  >
    <div class="col-12">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Título</mat-label>
        <input matInput formControlName="name" required />

        @if (data.get('name')?.touched &&
        data.get('name')?.hasError('required')) {
        <mat-error> El título es <strong>requerido</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="col-12">
      <ng-select
        id="tag"
        appearance="outline"
        [items]="tags"
        bindLabel="name"
        bindValue="id"
        formControlName="tags"
        placeholder="Tags"
        [multiple]="true"
        [clearable]="false"
      ></ng-select>

      @if (data.get('tags')?.touched && data.get('tags')?.hasError('required'))
      {
      <div id="tagValidation" class="invalid-feedback">
        El país es <strong>requerido</strong>
      </div>
      }
    </div>

    <div class="col-12 px-0">
      <app-image-controller
        [src]="currentTemplate.file"
        (selectFile)="selectFile($event)"
      ></app-image-controller>
    </div>

    <div class="col-lg-6">
      <ng-select
        id="country"
        appearance="outline"
        [items]="countries"
        bindLabel="name"
        bindValue="id"
        formControlName="countries"
        placeholder="Países"
        [multiple]="true"
        [clearable]="false"
      ></ng-select>

      @if (data.get('countries')?.touched &&
      data.get('countries')?.hasError('required')) {
      <div id="countryValidation" class="invalid-feedback">
        El país es <strong>requerido</strong>
      </div>
      }
    </div>

    <div class="col-lg-6">
      <ng-select
        id="state"
        appearance="outline"
        [items]="states"
        bindLabel="name"
        bindValue="id"
        formControlName="states"
        placeholder="Estados"
        [multiple]="true"
        [clearable]="false"
      ></ng-select>

      @if (data.get('states')?.touched &&
      data.get('states')?.hasError('required')) {
      <div id="stateValidation" class="invalid-feedback">
        El estado es <strong>requerido</strong>
      </div>
      }
    </div>

    <div class="col-12">
      <ng-select
        id="currency"
        appearance="outline"
        [items]="currencies"
        bindLabel="name"
        bindValue="id"
        formControlName="currency_id"
        placeholder="Moneda"
        [clearable]="false"
      ></ng-select>

      @if (data.get('currency_id')?.touched &&
      data.get('currency_id')?.hasError('required')) {
      <div id="currencyValidation" class="invalid-feedback">
        La moneda es <strong>requerida</strong>
      </div>
      }
    </div>

    <div class="col-lg-6">
      <ng-select
        id="type"
        appearance="outline"
        [items]="types"
        formControlName="type"
        placeholder="Tipo de descuento"
        [clearable]="false"
      ></ng-select>

      @if (data.get('type')?.touched && data.get('type')?.hasError('required'))
      {
      <div id="typeValidation" class="invalid-feedback">
        El tipo de descuento es <strong>requerido</strong>
      </div>
      }
    </div>

    <div class="col-6">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Descuento</mat-label>
        <input matInput type="number" formControlName="discount" required />

        @if (data.get('discount')?.touched &&
        data.get('discount')?.hasError('required')) {
        <mat-error> El descuento es <strong>requerido</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="col-12">
      <label for="discount-amount" class="form-label mb-2">Descripción</label>

      <angular-editor
        formControlName="description"
        [config]="editorConfig"
      ></angular-editor>
    </div>

    @if (initialized) {
    <div class="col-12 px-0">
      <app-date-controller
        [startDate]="data.get('start_at')?.value"
        [endDate]="data.get('end_at')?.value"
        (startDateChange)="data.get('start_at')?.setValue($event)"
        (endDateChange)="data.get('end_at')?.setValue($event)"
      ></app-date-controller>
    </div>
    }

    <app-alert [content]="alertData"></app-alert>

    <div class="col-12 px-0">
      <app-crud-form-footer
        [id]="dataId()"
        [created]="created"
        [loading]="loadingService.getLoading()"
        (cancel)="cancel()"
      ></app-crud-form-footer>
    </div>
  </form>
</div>
