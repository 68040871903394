import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { NgSelectModule } from '@ng-select/ng-select';

import { Observable, forkJoin } from 'rxjs';

import { AlertComponent } from '@shared/components/alert/alert.component';
import { AutofocusDirective } from '@shared/directives/autofocus.directive';

import { Speaker } from '@admin/models/Speakers.model';
import { DateModel } from '@admin/models/Date.model';

import { CrudFormFooterComponent } from '@admin/components/crud-form-footer/crud-form-footer.component';
import { CrudFormHeaderComponent } from '@admin/components/crud-form-header/crud-form-header.component';
import { CrudFormComponent } from '@admin/components/crud-form/crud-form.component';
import { DateControllerComponent } from '@admin/components/date-controller/date-controller.component';
import { ScheduleDateControllerComponent } from '@admin/components/schedule-date-controller/schedule-date-controller.component';
import { ImageControllerComponent } from '@admin/components/image-controller/image-controller.component';

@Component({
  selector: 'app-schedules-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    NgSelectModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatInputModule,

    AutofocusDirective,

    AlertComponent,
    CrudFormHeaderComponent,
    CrudFormFooterComponent,
    DateControllerComponent,
    ImageControllerComponent,
    ScheduleDateControllerComponent,
  ],
  templateUrl: './schedules-form.component.html',
  styleUrl: './schedules-form.component.scss',
})
export class SchedulesFormComponent extends CrudFormComponent {
  dates: DateModel[] = [];
  speakers: Speaker[] = [];

  override services: Observable<any>[] = [
    this.api.call(`admin/speakers?limit=1000&event_id=${this.eventId()}`),
  ];

  override data = this.formBuilder.group({
    name: ['', [Validators.required]],
    description: ['', [Validators.required]],
    active: [true, Validators.required],
    image: ['', [Validators.required]],
    speakers: [[], Validators.required],
  });

  override getParams() {
    const params: any = this.data.value;
    params['active'] = Number(params['active']);

    if (this.eventId() !== -1) {
      params['event_id'] = this.eventId();
    }

    params['dates'] = this.dates;
    params['image'] = this.currentTemplate.file;

    return params;
  }

  override handleLoadResponse(responses: any[]): void {
    console.log(this.endpoint);

    if (responses[0] && responses[0].status === 200)
      this.speakers = responses[0].data.rows;

    if (
      this.dataId() !== -1 &&
      responses[responses.length - 1] &&
      responses[responses.length - 1].status === 200
    ) {
      const responseData = responses[responses.length - 1].data;

      this.data.patchValue(responseData);

      const dates: any = responseData['dates'];

      if (dates && Array.isArray(dates)) {
        this.dates = dates.map((date: any) => {
          const nDate = new DateModel();

          nDate.start_at = date['start_at'];
          nDate.end_at = date['end_at'];

          return nDate;
        });
      }

      if (responseData['image']) {
        this.currentTemplate.file = responseData['image'];

        if (
          responseData['documents'] &&
          Array.isArray(responseData['documents'])
        ) {
          this.pdfFiles = responseData['documents'];
        }
      }
    } else {
      this.newDate();
    }
  }

  override submit() {
    if (this.loadingService.getLoading() || !this.isValid()) return;

    this.startLoading();

    const params = this.getParams();

    const services = [this.api.call(this.endpoint, this.method, params)];

    console.log(this.pdfFiles);

    this.pdfFiles.forEach((pdfFile) => {
      services.push(
        this.api.call(`${this.endpoint}/document`, 'post', {
          name: pdfFile.name,
          content: pdfFile.file,
        })
      );
    });

    forkJoin(services).subscribe({
      next: (responses) => {
        if (responses[0].status === 200) {
          this.success(responses[0]);
        } else {
          this.failed(responses[0].message);
        }

        const fileResponses = responses.slice(1);
        let errors: string[] = [];

        fileResponses.forEach((response) => {
          if (response.status !== 200) {
            errors.push(
              response.message.join(', ') || 'Error al subir el archivo'
            );
          }
        });
      },
      error: (errors) => {
        this.failed(errors);
      },
    });

    this.api.call(this.endpoint, this.method, params).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.success(response);
        } else {
          this.failed(response.message);
        }
      },
      error: (error) => {
        this.failed(error);
      },
    });
  }

  override handleFile() {
    if (this.currentTemplate.file) {
      this.data.get('image')?.setValue(this.currentTemplate.file);
    }
  }

  newDate() {
    this.dates.push(new DateModel());
  }

  deleteDate(index: number) {
    this.dates.splice(index, 1);
  }
}
