import { CommonModule } from '@angular/common';
import {
  Component,
  HostListener,
  Signal,
  computed,
  signal,
} from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';

import { MatSidenavModule } from '@angular/material/sidenav';
import { forkJoin } from 'rxjs';

import { SharedModule } from '@shared/shared.module';

import { ApiService } from '@services/api.service';
import { LoadingService } from '@services/loading.service';

import { Pagination } from '@shared/models/Pagination.model';

import { FooterComponent, HeaderComponent, SidebarComponent } from './layout';
import { MenuItem } from './models/MenuItem.model';
import { EventModel } from './models/Event.model';
import { RolesService } from '@services/roles.service';

const MIN_SCREEN_SIZE = 992;

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    MatSidenavModule,
    SharedModule,
  ],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss',
})
export class AdminComponent {
  data: Pagination<EventModel> = new Pagination(EventModel);
  event: EventModel = new EventModel();
  eventId = signal(-1);
  initialized: boolean = false;
  isMobile: boolean = false;
  loading = signal(false);
  menuItems: MenuItem[] = [];
  params: any = {
    page: 1,
    take: 10,
  };
  group = signal('');
  canShow: Signal<boolean> = computed(() =>
    this.roles.can(this.group(), 'index')
  );
  canCreate: Signal<boolean> = computed(() =>
    this.roles.can(this.group(), 'create')
  );

  constructor(
    private api: ApiService,
    protected loadingService: LoadingService,
    private route: ActivatedRoute,
    private roles: RolesService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = window.innerWidth < MIN_SCREEN_SIZE;
  }

  ngOnInit(): void {
    this.isMobile = window.innerWidth < MIN_SCREEN_SIZE;

    this.route.paramMap.subscribe((params) => {
      const eventId = params.get('eventid');

      if (eventId) {
        this.eventId.set(Number(eventId));
      }
    });

    this.loadingService.start();

    const services = [
      this.api.call('admin/menu'),
      this.api.call('admin/events', 'get', this.params),
    ];

    const eventId = this.route.snapshot.paramMap.get('eventid');

    if (eventId) {
      this.eventId.set(Number(eventId));
      this.group.set('admin.events');
    }

    forkJoin(services).subscribe({
      next: ([menuRes, eventRes]) => {
        if (menuRes.status === 200) {
          this.menuItems = menuRes.data.map((menu: any) => {
            const nItem = new MenuItem();
            nItem.setData(menu);

            return nItem;
          });
        }

        if (eventRes.status === 200) {
          this.data.setData(eventRes.data);

          if (this.eventId() === -1 && this.data.rows[0]) {
            this.eventId.set(this.data.rows[0].id);
          }

          const event = this.data.rows.find(
            (event) => event.id === this.eventId()
          );

          if (event) {
            this.event = event;
          }
        }

        this.initialized = true;

        this.loadingService.stop();
      },
      error: ([menuError]) => {
        console.error(menuError);
        this.initialized = true;

        this.loadingService.stop();
      },
    });
  }

  pagination(next: boolean = false) {
    this.params['page'] = this.params['page'] + (next ? 1 : -1);

    this.api.call('admin/events', 'get', this.params).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.data.setData(response.data);
        }

        this.loading.set(false);
      },
      error: (error) => {
        this.loading.set(false);
      },
    });
  }
}
