<div [ngClass]="titleContainer">
  <div class="row align-items-center justify-content-evenly">
    <div class="col-lg mb-3 mb-lg-0 ps-lg-0">
      <h3 class="mb-0">{{ title }}</h3>
    </div>

    @if (canCreate()) {
    <div class="col-lg-auto text-end mb-3 mb-lg-0 pe-lg-0">
      <button
        mat-raised-button
        class="px-4 py-3"
        color="primary"
        [routerLink]="[createRoute + '/create']"
      >
        <mat-icon>add</mat-icon>

        Nuevo
      </button>
    </div>
    }
  </div>
</div>
<hr />
