import { EventModel } from '@admin/models/Event.model';
import {
  Component,
  Signal,
  WritableSignal,
  computed,
  signal,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@services/api.service';
import { LoadingService } from '@services/loading.service';
import { Pagination } from '@shared/models/Pagination.model';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {
  events: WritableSignal<EventModel[]> = signal([]);
  eventId = signal(-1);
  currentEvent: Signal<EventModel> = computed(() => {
    const event = this.events().filter((row) => row.id === this.eventId())[0];

    if (event) return event;

    return new EventModel();
  });

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private loading: LoadingService
  ) {}

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.loading.start();
    const eventId = this.route.parent?.snapshot.paramMap.get('eventid');

    if (eventId) this.eventId.set(Number(eventId));

    this.api
      .call('admin/events', 'get', {
        take: 1000,
      })
      .subscribe({
        next: (response) => {
          if (response.status === 200) {
            this.events.set(
              response.data.rows.map((row: any) => {
                const nEve = new EventModel();
                nEve.setData(row);

                return nEve;
              })
            );

            if (this.eventId() === -1 && this.events()[0]) {
              this.eventId.set(this.events()[0].id);
            }
          }

          this.loading.stop();
        },
        error: (error) => {
          console.error(error);

          this.loading.stop();
        },
      });
  }
}
