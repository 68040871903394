import { Component, Signal, computed, inject, signal } from '@angular/core';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { FormComponent } from '@shared/components';
import { ElementModel } from '@shared/models/Element.model';
import { Observable, forkJoin } from 'rxjs';
import { Search, SearchBarComponent } from '../search-bar/search-bar.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Column } from '@shared/models/Pagination.model';
import { RolesService } from '@services/roles.service';
import { MatIconModule } from '@angular/material/icon';
import { AlertComponent } from '@shared/components/alert/alert.component';
import { CommonModule } from '@angular/common';
import { CrudHeaderComponent } from '../crud-header/crud-header.component';

@Component({
  selector: 'app-crud',
  standalone: true,
  imports: [
    CommonModule,
    MatPaginatorModule,
    RouterModule,
    MatIconModule,
    AlertComponent,
    CrudHeaderComponent,
    SearchBarComponent,
  ],
  templateUrl: './crud.component.html',
  styleUrl: './crud.component.scss',
})
export class CrudComponent extends FormComponent {
  protected modalService = inject(NgbModal);
  protected route = inject(ActivatedRoute);
  protected router = inject(Router);
  protected roles = inject(RolesService);

  crudRoute = signal('');
  eventId = signal(-1);
  filters: ElementModel[] = [];
  group = signal('');
  pagination = signal(true);
  services: Observable<any>[] = [];
  title: string = 'Elementos';
  params: any = {
    take: 12,
    page: 1,
    search: '',
    event_id: -1,
    order: [],
  };

  canShow: Signal<boolean> = computed(() =>
    this.roles.can(this.group(), 'show')
  );
  canEdit: Signal<boolean> = computed(() =>
    this.roles.can(this.group(), 'edit')
  );
  canDelete: Signal<boolean> = computed(() =>
    this.roles.can(this.group(), 'destroy')
  );
  canDesactive: Signal<boolean> = computed(() =>
    this.roles.can(this.group(), 'desactive')
  );

  ngOnInit() {
    const parts = this.router.url.split('/');
    const eventid = this.route.parent?.snapshot.paramMap.get('eventid');

    if (eventid) {
      this.eventId.set(Number(eventid));
      this.params['event_id'] = this.eventId();
    }

    this.endpoint = parts.join('/').substring(this.eventId() !== -1 ? 3 : 1);

    this.group.set(this.endpoint.replace('/', '.'));
    this.crudRoute.set(
      this.eventId() !== -1
        ? `/${this.eventId()}/${this.endpoint}`
        : `/${this.endpoint}`
    );

    this.load();
  }

  showModal(data: any) {}

  delete(id: number) {
    if (this.endpoint) {
      const modalRef = this.modalService.open(DeleteModalComponent);

      modalRef.result.then((result) => {
        if (result) {
          this.startLoading();

          this.api.call(this.endpoint + '/' + id, 'delete', {}).subscribe({
            next: (responses) => {
              if (responses.status === 200) {
                this.handleAlert('Elemento eliminado correctamente', 'success');
              } else {
                this.handleAlert('¡Ocurrio un error al eliminar!');
              }

              this.load();
            },
            error: (error) => {
              console.error(error);
              this.handleAlert(error);
              this.stopLoading();
            },
          });
        }
      });
    }
  }

  getServices() {
    return [this.api.call(this.endpoint, 'get', this.params)];
  }

  sort(column: Column) {
    let sorted =
      typeof this.params.order == 'string'
        ? this.params.order.split('|')[1]
        : '';
    this.params.order = `${column.field.split('.')[0]}|${
      sorted == 'asc' ? 'desc' : 'asc'
    }`;

    this.load();
  }

  load(init: boolean = false) {
    const services = init ? this.services : this.getServices();

    if (services.length === 0) {
      return;
    }

    this.startLoading();

    forkJoin(services).subscribe({
      next: (responses) => {
        this.handleLoadResponse(responses);
        this.stopLoading();
      },
      error: (err) => {
        this.handleErrors(err);
        this.stopLoading();
      },
    });
  }

  handleLoadResponse(responses: any[]) {}

  handleErrors(errors: any[]) {}

  handlePage(event: PageEvent) {
    this.params.take = event.pageSize;
    this.params.page = event.pageIndex + 1;

    this.load();
  }

  handleSearch(search: Search) {
    if (search.term) {
      this.params.search = encodeURIComponent(search.term);
    }

    if (search.filter) {
      this.params.filter = search.filter;
    }

    this.load();
  }
}
