import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';

import { ColorPickerModule } from 'ngx-color-picker';

import { CrudFormComponent } from '@admin/components/crud-form/crud-form.component';
import { Section } from '@admin/models/Event.model';
import { NgSelectModule } from '@ng-select/ng-select';
import { Observable } from 'rxjs';
import { AutofocusDirective } from '@shared/directives/autofocus.directive';
import { EventSectionComponent } from '@admin/components/event-section/event-section.component';
import { AlertComponent } from '@shared/components/alert/alert.component';
import { DateControllerComponent } from '@admin/components/date-controller/date-controller.component';
import { CrudFormHeaderComponent } from '@admin/components/crud-form-header/crud-form-header.component';
import { CrudFormFooterComponent } from '@admin/components/crud-form-footer/crud-form-footer.component';

@Component({
  selector: 'app-events-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ColorPickerModule,
    NgSelectModule,
    AutofocusDirective,
    AlertComponent,
    CrudFormHeaderComponent,
    CrudFormFooterComponent,
    EventSectionComponent,
    DateControllerComponent,
  ],
  templateUrl: './events-form.component.html',
  styleUrl: './events-form.component.scss',
})
export class EventsFormComponent extends CrudFormComponent {
  allSections: string[] = [];
  fonts: string[] = [];
  sections: Section[] = [];
  timeZones: any[] = [];

  override services: Observable<any>[] = [
    this.api.call('admin/catalogs/events/sections'),
    this.api.call('admin/catalogs/events/fonts'),
  ];

  override data = this.formBuilder.group({
    name: ['', [Validators.required]],
    timezone: ['', [Validators.required]],
    location: ['', [Validators.required]],
    image: ['', [Validators.required]],
    enterprise_id: ['', Validators.required],
    active: ['', Validators.required],
    countries: [[], Validators.required],

    font: ['', [Validators.required]],
    primary: ['', [Validators.required]],
    secondary: ['', [Validators.required]],
    tertiary: ['', [Validators.required]],
    start_at: ['', Validators.required],
    end_at: ['', Validators.required],
  });

  override formatEndpoint(): void {
    this.endpoint = 'admin/' + this.endpoint;
  }

  override handleLoadResponse(responses: any[]): void {
    if (responses[0] && responses[0].status === 200)
      this.allSections = responses[0].data;

    if (responses[1] && responses[1].status === 200)
      this.fonts = responses[1].data;

    if (
      this.dataId() !== -1 &&
      responses[responses.length - 1] &&
      responses[responses.length - 1].status === 200
    ) {
      const eventData = responses[responses.length - 1].data;
      this.data.patchValue(eventData);
      this.data.patchValue(eventData.theme);
      this.data.patchValue(eventData.theme?.color);

      this.sections = eventData.sections.map((sectionD: any) => {
        const nSec = new Section();
        nSec.setData(sectionD);
        return nSec;
      });

      if (
        this.data.get('image')?.value &&
        typeof this.data.get('image')?.value === 'string'
      ) {
        this.currentTemplate.file = String(this.data.get('image')?.value ?? '');
      }
    } else {
      if (this.sections.length === 0) {
        const nSection = new Section();

        this.sections.push(nSection);
      }
    }
  }

  override isValid(): boolean {
    return true;
  }

  override getParams() {
    const { primary, secondary, tertiary, font, ...params }: any =
      this.data.value;

    params['theme'] = {
      color: {
        primary,
        secondary,
        tertiary,
      },
      font,
    };

    params['sections'] = [...this.sections];

    return params;
  }

  override submit(): void {
    this.getParams();
  }

  addSection(): void {
    const nSection = new Section();
    this.sections.push(nSection);
  }

  deleteSection(secInd: number): void {
    this.sections.splice(secInd, 1);
  }
}
