import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';

import { Observable } from 'rxjs';

import { CrudFormComponent } from '@admin/components/crud-form/crud-form.component';

import { AutofocusDirective } from '@shared/directives/autofocus.directive';
import { AlertComponent } from '@shared/components/alert/alert.component';
import { CrudFormHeaderComponent } from '@admin/components/crud-form-header/crud-form-header.component';
import { CrudFormFooterComponent } from '@admin/components/crud-form-footer/crud-form-footer.component';
import { DateControllerComponent } from '@admin/components/date-controller/date-controller.component';

@Component({
  selector: 'app-notifications-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    ReactiveFormsModule,

    AutofocusDirective,

    AlertComponent,
    CrudFormHeaderComponent,
    CrudFormFooterComponent,
    DateControllerComponent,
  ],
  templateUrl: './notifications-form.component.html',
  styleUrl: './notifications-form.component.scss',
})
export class NotificationsFormComponent extends CrudFormComponent {
  override services: Observable<any>[] = [
    this.api.call('admin/countries?limit=1000'),
    this.api.call('admin/states?limit=1000'),
  ];

  override data = this.formBuilder.group({
    name: ['', [Validators.required]],
    body: ['', [Validators.required]],
    active: [true, Validators.required],
    start_at: [''],
  });

  override getParams() {
    const params: any = this.data.value;
    params['active'] = Number(params['active']);

    if (this.eventId() !== -1) {
      params['event_id'] = this.eventId();
    }

    return params;
  }

  override handleLoadResponse(responses: any[]): void {
    if (
      this.dataId() !== -1 &&
      responses[responses.length - 1] &&
      responses[responses.length - 1].status === 200
    ) {
      const data = responses[responses.length - 1].data;

      this.data.patchValue(data);
    }
  }
}
