import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { MatInputModule } from '@angular/material/input';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { Observable } from 'rxjs';
import { NgSelectModule } from '@ng-select/ng-select';

import { Country } from '@admin/models/Country.model';
import { CrudElement } from '@admin/models/CrudElement.model';
import { State } from '@admin/models/State.model';
import { Tag } from '@admin/models/Tag.model';

import { AlertComponent } from '@shared/components/alert/alert.component';
import { AutofocusDirective } from '@shared/directives/autofocus.directive';
import { editorConfig } from '@shared/config/editor-config';

import {
  CrudFormComponent,
  CrudFormFooterComponent,
  CrudFormHeaderComponent,
  DateControllerComponent,
  ImageControllerComponent,
} from '@admin/components';

@Component({
  selector: 'app-coupons-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    NgSelectModule,
    MatInputModule,

    AutofocusDirective,

    AlertComponent,
    CrudFormHeaderComponent,
    CrudFormFooterComponent,
    DateControllerComponent,
    ImageControllerComponent,
  ],
  templateUrl: './coupons-form.component.html',
  styleUrl: './coupons-form.component.scss',
})
export class CouponsFormComponent extends CrudFormComponent {
  countries: Country[] = [];
  currencies: CrudElement[] = [];
  states: State[] = [];
  tags: Tag[] = [];
  types: string[] = [];
  editorConfig = editorConfig;

  override services: Observable<any>[] = [
    this.api.call('admin/countries?limit=1000&page=1'),
    this.api.call('admin/currencies?limit=1000&page=1'),
    this.api.call('admin/states?limit=1000&page=1'),
    this.api.call(`admin/tags?limit=1000&page=1&event_id=${this.eventId()}`),
    this.api.call('admin/catalogs/coupons/types'),
  ];

  override data = this.formBuilder.group({
    name: ['', [Validators.required]],
    amount: ['', [Validators.required]],
    type: new FormControl<'' | null>(null),
    description: ['', [Validators.required]],
    discount: ['', [Validators.required]],
    image: ['', [Validators.required]],
    start_at: [''],
    end_at: [''],
    currency_id: new FormControl<number | null>(null, Validators.required),
    active: [1, [Validators.required]],
    countries: new FormControl<number[] | null>(null, Validators.required),
    states: new FormControl<number[] | null>(null, Validators.required),
    tags: new FormControl<number[] | null>(null, Validators.required),
  });

  override handleLoadResponse(responses: any[]): void {
    if (responses[0] && responses[0].status === 200)
      this.countries = responses[0].data.rows;

    if (responses[1] && responses[1].status === 200)
      this.currencies = responses[1].data.rows;

    if (responses[2] && responses[2].status === 200)
      this.states = responses[2].data.rows;

    if (responses[3] && responses[3].status === 200)
      this.tags = responses[3].data.rows;

    if (responses[4] && responses[4].status === 200)
      this.types = responses[4].data;

    if (
      this.dataId() !== -1 &&
      responses[responses.length - 1] &&
      responses[responses.length - 1].status === 200
    ) {
      const responseData = responses[responses.length - 1].data;
      this.data.patchValue(responseData);

      if (
        this.data.get('image')?.value &&
        typeof this.data.get('image')?.value === 'string'
      ) {
        this.currentTemplate.file = String(this.data.get('image')?.value ?? '');
      }
    }
  }

  override handleFile() {
    if (this.currentTemplate.file) {
      this.data.get('image')?.setValue(this.currentTemplate.file);
    }
  }

  override getParams() {
    const params: any = this.data.value;

    if (this.eventId() !== -1) {
      params['event_id'] = this.eventId();
    }

    return params;
  }
}
