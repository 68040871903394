import { Routes } from '@angular/router';

import { environment } from '@environments';
import { adminGuard } from '@shared/guards/admin.guard';

import {
  ArticlesComponent,
  ArticlesFormComponent,
  CountriesComponent,
  CountriesFormComponent,
  CouponsComponent,
  CouponsFormComponent,
  CurrenciesComponent,
  CurrenciesFormComponent,
  EnterprisesComponent,
  EnterprisesFormComponent,
  HomeComponent,
  NotificationsComponent,
  NotificationsFormComponent,
  PollsComponent,
  PollsFormComponent,
  RankingComponent,
  RedeemsComponent,
  SchedulesComponent,
  SchedulesFormComponent,
  SpeakersComponent,
  SpeakersFormComponent,
  StatesComponent,
  StatesFormComponent,
  TagsComponent,
  TagsFormComponent,
  TriviasComponent,
  TriviasFormComponent,
  UsersComponent,
  UsersFormComponent,
} from './pages';

export const adminRoutes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    title: `Home - ${environment.title}`,
  },
  {
    path: 'articles',
    component: ArticlesComponent,
    title: `Articulos - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.articles.index'],
    },
  },
  {
    path: 'articles/create',
    component: ArticlesFormComponent,
    title: `Articulos - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.articles.index'],
    },
  },
  {
    path: 'articles/edit/:id',
    component: ArticlesFormComponent,
    title: `Articulos - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.articles.index'],
    },
  },
  {
    path: 'countries',
    component: CountriesComponent,
    title: `Ciudades - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.countries.index'],
    },
  },
  {
    path: 'countries/create',
    component: CountriesFormComponent,
    title: `Ciudades - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.countries.index'],
    },
  },
  {
    path: 'countries/edit/:id',
    component: CountriesFormComponent,
    title: `Ciudades - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.countries.index'],
    },
  },
  {
    path: 'coupons',
    component: CouponsComponent,
    title: `Cupones - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.coupons.index'],
    },
  },
  {
    path: 'coupons/create',
    component: CouponsFormComponent,
    title: `Cupones - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.coupons.edit'],
    },
  },
  {
    path: 'coupons/edit/:id',
    component: CouponsFormComponent,
    title: `Cupones - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.coupons.edit'],
    },
  },
  {
    path: 'currencies',
    component: CurrenciesComponent,
    title: `Monedas - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.currencies.index'],
    },
  },
  {
    path: 'currencies/create',
    component: CurrenciesFormComponent,
    title: `Monedas - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.currencies.index'],
    },
  },
  {
    path: 'currencies/edit/:id',
    component: CurrenciesFormComponent,
    title: `Monedas - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.currencies.index'],
    },
  },
  {
    path: 'enterprises',
    component: EnterprisesComponent,
    title: `Empresas - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.enterprises.index'],
    },
  },
  {
    path: 'enterprises/create',
    component: EnterprisesFormComponent,
    title: `Empresas - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.enterprises.index'],
    },
  },
  {
    path: 'enterprises/edit/:id',
    component: EnterprisesFormComponent,
    title: `Empresas - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.enterprises.index'],
    },
  },
  {
    path: 'notifications',
    component: NotificationsComponent,
    title: `Notificaciones - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.notifications.index'],
    },
  },
  {
    path: 'notifications/create',
    component: NotificationsFormComponent,
    title: `Notificaciones - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.notifications.index'],
    },
  },
  {
    path: 'notifications/edit/:id',
    component: NotificationsFormComponent,
    title: `Notificaciones - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.notifications.index'],
    },
  },
  {
    path: 'polls',
    component: PollsComponent,
    title: `Encuestas - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.polls.index'],
    },
  },
  {
    path: 'polls/create',
    component: PollsFormComponent,
    title: `Encuestas - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.polls.index'],
    },
  },
  {
    path: 'polls/edit/:id',
    component: PollsFormComponent,
    title: `Encuestas - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.polls.index'],
    },
  },
  {
    path: 'redeems',
    component: RedeemsComponent,
    title: `Canjes - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.redeems.index'],
    },
  },
  {
    path: 'rankings',
    component: RankingComponent,
    title: `Ranking - ${environment.title}`,
    canActivate: [adminGuard],
  },
  {
    path: 'states',
    component: StatesComponent,
    title: `Estados - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.states.index'],
    },
  },
  {
    path: 'states/create',
    component: StatesFormComponent,
    title: `Estados - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.states.index'],
    },
  },
  {
    path: 'states/edit/:id',
    component: StatesFormComponent,
    title: `Estados - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.states.index'],
    },
  },
  {
    path: 'schedules',
    component: SchedulesComponent,
    title: `Horarios - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.schedules.index'],
    },
  },
  {
    path: 'schedules/create',
    component: SchedulesFormComponent,
    title: `Horarios - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.schedules.index'],
    },
  },
  {
    path: 'schedules/edit/:id',
    component: SchedulesFormComponent,
    title: `Horarios - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.schedules.index'],
    },
  },
  {
    path: 'speakers',
    component: SpeakersComponent,
    title: `Ponentes - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.speakers.index'],
    },
  },
  {
    path: 'speakers/create',
    component: SpeakersFormComponent,
    title: `Ponentes - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.speakers.index'],
    },
  },
  {
    path: 'speakers/edit/:id',
    component: SpeakersFormComponent,
    title: `Ponentes - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.speakers.index'],
    },
  },
  {
    path: 'tags',
    component: TagsComponent,
    title: `Etiquetas - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.tags.index'],
    },
  },
  {
    path: 'tags/create',
    component: TagsFormComponent,
    title: `Etiquetas - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.tags.index'],
    },
  },
  {
    path: 'tags/edit/:id',
    component: TagsFormComponent,
    title: `Etiquetas - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.tags.index'],
    },
  },
  {
    path: 'trivias',
    component: TriviasComponent,
    title: `Trivias - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.trivias.index'],
    },
  },
  {
    path: 'trivias/create',
    component: TriviasFormComponent,
    title: `Trivias - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.trivias.index'],
    },
  },
  {
    path: 'trivias/edit/:id',
    component: TriviasFormComponent,
    title: `Trivias - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.trivias.index'],
    },
  },
  {
    path: 'users',
    component: UsersComponent,
    title: `Usuarios - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.users.index'],
    },
  },
  {
    path: 'users/create',
    component: UsersFormComponent,
    title: `Usuarios - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.users.index'],
    },
  },
  {
    path: 'users/edit/:id',
    component: UsersFormComponent,
    title: `Usuarios - ${environment.title}`,
    canActivate: [adminGuard],
    data: {
      requiredRoles: ['admin.users.index'],
    },
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];
