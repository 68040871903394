import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-image-controller',
  standalone: true,
  imports: [],
  templateUrl: './image-controller.component.html',
  styleUrl: './image-controller.component.scss',
})
export class ImageControllerComponent {
  @Input() src: string = 'assets/images/logo.png';
  @Input() alt: string = 'Imagen';
  @Input() disable: boolean = false;
  @Input() label: string = 'Imagen';
  @Output() selectFile: EventEmitter<any> = new EventEmitter();
}
