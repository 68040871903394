// USED AS MENUS TO SIDEBAR

import { Main } from '@shared/models/Main.model';

export class MenuItem extends Main {
  id: number = -1;
  title: string = '';
  icon: string = '';
  type: string = '';
  permission: string = '';
  children: MenuChild[] = [];
  collapse: boolean = true;
}

export class MenuChild {
  title: string = '';
  icon: string = '';
  type: string = '';
  permission: string = '';
}
