<div class="row">
  <div class="col-12">
    <h1 class="mb-0">
      {{ disable ? "" : id !== -1 ? "Editar" : "Nuev" + (newA ? "a" : "o") }}
      {{ title }}
    </h1>
  </div>

  <div class="col-12 my-3 mb-lg-5">
    <hr />
  </div>
</div>
