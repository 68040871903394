import { PollQuestion } from '@admin/models/PollQuestion.model';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-poll-question',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    NgbCollapseModule,

    CdkDrag,
    CdkDragHandle,
    CdkDropList,
  ],
  templateUrl: './poll-question.component.html',
  styleUrl: './poll-question.component.scss',
})
export class PollQuestionComponent {
  @Input() data: PollQuestion = new PollQuestion();
  @Input() types: string[] = [];

  isCollapsed = false;

  constructor(protected formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.handleOptions();
  }

  handleOptions() {
    if (this.data.type === 'options' && !this.data.questions) {
      this.data.questions = [''];
    }
  }

  addOption() {
    if (!this.data.questions) return;

    this.data.questions.push('');
  }

  deleteOption(index: number) {
    if (!this.data.questions) return;

    this.data.questions.splice(index, 1);
  }

  drop(event: CdkDragDrop<PollQuestion[]>) {
    if (!this.data.questions) return;

    moveItemInArray(
      this.data.questions,
      event.previousIndex,
      event.currentIndex
    );
  }

  raiseQs(index: number) {
    if (!this.data.questions) return;

    if (index >= 0) {
      const currentQuestion = this.data.questions[index];
      const nextQuestion = this.data.questions[index - 1];

      // Intercambiar las posiciones
      this.data.questions[index] = nextQuestion;
      this.data.questions[index - 1] = currentQuestion;
    }
  }

  lowerQs(index: number) {
    if (!this.data.questions) return;

    if (index < this.data.questions.length - 1) {
      const currentQuestion = this.data.questions[index];
      const previousQuestion = this.data.questions[index + 1];

      // Intercambiar las posiciones
      this.data.questions[index] = previousQuestion;
      this.data.questions[index + 1] = currentQuestion;
    }
  }
}
