<div class="container-fluid">
  <div class="row py-3 py-lg-4">
    <app-crud-header
      title="Evento"
      [createRoute]="crudRoute()"
    ></app-crud-header>

    @if (!loadingService.loader()) {
    <div class="col-xl-4 mt-4 px-0">
      <div class="row">
        <div class="col-12 mb-4">
          <h5 class="mb-0"><strong>Próximos eventos</strong></h5>
        </div>

        <div class="col-12">
          <div class="list-group">
            @for (event of listEvents; track $index) {
            <li class="list-group-item px-1 px-xl-3">
              <div class="row align-items-center py-2">
                <div class="col-xxl-8 px-0 mb-3 mb-xxl-0">
                  <div class="row">
                    <div class="col-12 mb-2">
                      <div class="fw-bold">{{ event.title }}</div>
                    </div>

                    <div class="col-12">
                      {{ event.date | date : "dd/MM/yyyy" }}
                    </div>
                  </div>
                </div>

                <div class="col-xxl-4">
                  <a
                    class="btn btn-primary w-100"
                    [routerLink]="[crudRoute() + '/edit/' + event.id]"
                  >
                    VER
                  </a>
                </div>
              </div>
            </li>

            <!-- <button
              type="button"
              class="list-group-item list-group-item-action py-3"
              aria-current="true"
            >
              {{ event.date | date : "dd/MM/yyyy" }}
            </button> -->
            } @empty { No hay eventos próximos }
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-8 mt-4 calendar-container">
      <app-schedule-calendar
        [crudRoute]="crudRoute()"
        [events]="events"
      ></app-schedule-calendar>
    </div>
    }
  </div>
</div>
