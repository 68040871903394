<div class="crud-page">
  <div class="crud-content">
    <div class="container-fluid py-3 py-lg-4">
      <div class="row justify-content-evenly">
        <app-crud-header
          title="Ponentes"
          [createRoute]="crudRoute()"
        ></app-crud-header>

        @if (data.rows.length > 0) {
        <div class="col-12 px-0 mb-3 mb-lg-4">
          <app-search-bar
            [filters]="filters"
            (onSearch)="handleSearch($event)"
          ></app-search-bar>
        </div>
        }

        <app-alert [content]="alertData"></app-alert>

        @for (coupon of data.rows; track $index) {
        <div class="col-sm-6 col-lg-4 col-xxl-3 mb-3 mb-lg-4">
          <div class="card shadow">
            <!-- [ngStyle]="{ 'background-color': event.theme.color.primary }" -->
            <div class="card-header text-bg-primary">
              <div class="d-flex w-100 align-items-center">
                <p class="h5 mb-0">
                  {{ coupon.name }}
                </p>

                <button
                  class="ms-auto"
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  aria-label="Example icon-button with a menu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #menu="matMenu" xPosition="before">
                  <button
                    mat-menu-item
                    [routerLink]="[crudRoute() + '/edit/' + coupon.id]"
                    routerLinkActive="router-link-active"
                  >
                    <mat-icon>edit</mat-icon>
                    <span>Editar</span>
                  </button>

                  <button
                    mat-menu-item
                    class="text-danger"
                    (click)="delete(coupon.id)"
                  >
                    <mat-icon class="text-danger">delete</mat-icon>
                    <span>Borrar</span>
                  </button>
                </mat-menu>
              </div>
            </div>

            <div class="card-body px-0">
              <div class="row">
                @if (coupon.image) {
                <div class="col-12 mb-3 mb-lg-4">
                  <img
                    [src]="coupon.image"
                    alt="Imagen empresa"
                    class="img-fluid"
                  />
                </div>
                }

                <div class="col-12">
                  <p>
                    <i class="fa-solid fa-location-dot me-2"></i>

                    {{ coupon.location }}
                  </p>

                  <p>
                    <i class="fa-solid fa-phone me-2"></i>

                    {{ coupon.phone }}
                  </p>

                  <p
                    class="text-end mb-0"
                    [ngClass]="
                      coupon.active === 1 ? 'text-success' : 'text-danger'
                    "
                  >
                    <i
                      class="me-2 fa-solid"
                      [ngClass]="coupon.active === 1 ? 'fa-check' : 'fa-x'"
                    ></i>

                    {{ coupon.active === 1 ? "Activo" : "No activo" }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        } @empty {
        <p class="h4 text-secondary">No hay elementos</p>
        }
      </div>
    </div>

    @if (pagination()) {
    <div class="crud-footer mb-auto">
      <mat-paginator
        [pageSizeOptions]="[10, 25, 50, 100]"
        [length]="data.total"
        [pageSize]="data.limit"
        aria-label="Selecciona pagina de placas"
        (page)="handlePage($event)"
      ></mat-paginator>
    </div>
    }
  </div>
</div>
