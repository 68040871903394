import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { CrudHeaderComponent } from '@admin/components/crud-header/crud-header.component';
import { CrudComponent } from '@admin/components/crud/crud.component';
import { ScheduleCalendarComponent } from '@admin/components/schedule-calendar/schedule-calendar.component';
import { CalendarEvent } from '@admin/models/CalendarEvent.model';
import { ScheduleEvent } from '@admin/models/ScheduleEvent.model';

import { ElementModel } from '@shared/models/Element.model';
import { Pagination } from '@shared/models/Pagination.model';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-schedules',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ScheduleCalendarComponent,
    CrudHeaderComponent,
  ],
  templateUrl: './schedules.component.html',
  styleUrl: './schedules.component.scss',
})
export class SchedulesComponent extends CrudComponent {
  events: CalendarEvent[] = [];
  listEvents: CalendarEvent[] = [];
  override data: Pagination<ScheduleEvent> = new Pagination(ScheduleEvent);
  override filters: ElementModel[] = [
    { label: 'Activo', value: '1' },
    { label: 'Inactivo', value: '0' },
  ];

  override handleLoadResponse(responses: any[]): void {
    if (responses[0].data) this.data.setData(responses[0].data);

    this.data.rows.forEach((schedule) => {
      const calendars: CalendarEvent[] = [];

      schedule.dates.forEach((date) => {
        const startCalendar = new CalendarEvent();

        startCalendar.id = schedule.id;
        startCalendar.title = schedule.name + ' (Inicio)';
        startCalendar.date = date.start_at ?? '';

        const endCalendar = new CalendarEvent();

        endCalendar.id = schedule.id;
        endCalendar.title = schedule.name + ' (Fin)';
        endCalendar.date = date.end_at ?? '';

        calendars.push(startCalendar);
        calendars.push(endCalendar);
      });

      this.events.push(...calendars);
      this.listEvents.push(calendars[0]);
    });

    this.pagination.set(this.data.rows.length > 0);
    this.stopLoading();
  }
}
