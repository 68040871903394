<div class="row pt-4">
  <div class="col-lg mb-3 mb-lg-0">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Búsqueda por nombre</mat-label>
      <input type="search" matInput [(ngModel)]="data.term" />
    </mat-form-field>
  </div>

  @if (filters.length > 0) {
  <div class="col-lg">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Filtrar</mat-label>
      <mat-select [(ngModel)]="data.filter" (ngModelChange)="search()">
        @for (filter of filters; track filter) {
        <mat-option [value]="filter.value">{{ filter.label }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  }

  <div class="col-lg-auto">
    <button
      mat-raised-button
      color="primary"
      class="px-4"
      (click)="search()"
      [disabled]="disabled"
    >
      Buscar
      <mat-icon>search</mat-icon>
    </button>
  </div>
</div>
