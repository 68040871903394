import {
  CdkDrag,
  CdkDragHandle,
  CdkDropList,
  CdkDragDrop,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, FormBuilder } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import {
  TriviaAnswer,
  TriviaQuestion,
} from '@admin/models/TriviaQuestion.model';

@Component({
  selector: 'app-trivia-question',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    NgbCollapseModule,

    CdkDrag,
    CdkDragHandle,
    CdkDropList,
  ],
  templateUrl: './trivia-question.component.html',
  styleUrl: './trivia-question.component.scss',
})
export class TriviaQuestionComponent {
  @Input() data: TriviaQuestion = new TriviaQuestion();
  @Input() index: number = 0;
  @Input() length: number = 0;
  @Input() types: string[] = [];
  @Output() delete: EventEmitter<void> = new EventEmitter();

  isCollapsed = false;

  constructor(protected formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.handleOptions();
  }

  handleOptions() {
    if (!this.data.answers || this.data.answers.length === 0) {
      const nAns = new TriviaAnswer();
      this.data.answers.push(nAns);
    }
  }

  addOption() {
    const nAns = new TriviaAnswer();
    this.data.answers.push(nAns);
  }

  deleteOption(index: number) {
    this.data.answers.splice(index, 1);
  }

  drop(event: CdkDragDrop<TriviaAnswer[]>) {
    moveItemInArray(this.data.answers, event.previousIndex, event.currentIndex);
  }

  raiseQs(index: number) {
    if (index >= 0) {
      const currentQuestion = this.data.answers[index];
      const nextQuestion = this.data.answers[index - 1];

      // Intercambiar las posiciones
      this.data.answers[index] = nextQuestion;
      this.data.answers[index - 1] = currentQuestion;
    }
  }

  lowerQs(index: number) {
    if (index < this.data.answers.length - 1) {
      const currentQuestion = this.data.answers[index];
      const previousQuestion = this.data.answers[index + 1];

      // Intercambiar las posiciones
      this.data.answers[index] = previousQuestion;
      this.data.answers[index + 1] = currentQuestion;
    }
  }
}
