import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ElementModel } from '@shared/models/Element.model';

export interface Search {
  filter: string;
  term: string;
}

@Component({
  selector: 'app-search-bar',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
  ],
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss',
})
export class SearchBarComponent {
  @Input() disabled: boolean = false;
  @Input() filters: ElementModel[] = [];
  @Output() onSearch: EventEmitter<Search> = new EventEmitter();

  data: Search = {
    filter: '',
    term: '',
  };

  search() {
    console.log(this.data);
    this.onSearch.emit(this.data);
  }
}
