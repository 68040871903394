<div class="container">
  @if (currentEvent()) {
  <h1>{{ currentEvent().name }}</h1>

  <div class="row">
    <div class="col-12">
      Datos
    </div>
  </div>
  }
</div>
