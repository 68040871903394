import { Component, EventEmitter, Input, Output, inject } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-crud-form-footer',
  standalone: true,
  imports: [MatButtonModule, MatIconModule],
  templateUrl: './crud-form-footer.component.html',
  styleUrl: './crud-form-footer.component.scss',
})
export class CrudFormFooterComponent {
  @Input() id: number = -1;
  @Input() created: boolean = false;
  @Input() disable: boolean = false;
  @Input() loading: boolean = false;
  @Output() cancel: EventEmitter<void> = new EventEmitter();

  private activeModal = inject(NgbModal);

  // @HostListener('window:scroll', [])
  // onScroll(): void {
  //   const scrollPosition =
  //     window.pageYOffset ||
  //     document.documentElement.scrollTop ||
  //     document.body.scrollTop ||
  //     0;
  //   const windowHeight = window.innerHeight;
  //   const documentHeight = document.body.clientHeight;

  //   this.fixed = documentHeight - (scrollPosition + windowHeight) > 200;
  // }

  handleClose() {
    if (this.disable) {
      this.activeModal.dismissAll();
      return;
    }

    this.cancel.emit();
  }
}
