<div class="row h-100">
  <div class="col-12 px-0">
    <form class="row">
      <div class="col-lg-8 mb-3 mb-lg-0">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Pregunta</mat-label>
          <input name="question-title" matInput [(ngModel)]="data.title" />
        </mat-form-field>
      </div>

      <div class="col-lg-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Tipo de pregunta</mat-label>

          <mat-select
            name="resourceList"
            [(ngModel)]="data.type"
            (ngModelChange)="handleOptions()"
          >
            @for (type of types; track $index) {
            <mat-option [value]="type">{{ type }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </div>

  @if (data['type'] && data['type'] === 'options' && data.questions) {
  <div class="col-12 ms-auto mt-3 mt-lg-4">
    <div class="card border-dark">
      <div class="card-header bg-primary-subtle">
        <div class="d-flex w-100">
          <h5 class="my-2">Opciones</h5>

          <button
            type="button"
            class="btn btn-primary ms-auto"
            (click)="collapse.toggle()"
            [attr.aria-expanded]="!isCollapsed"
          >
            <i
              class="fa-solid"
              [ngClass]="isCollapsed ? 'fa-arrow-down' : 'fa-arrow-up'"
            ></i>
          </button>
        </div>
      </div>

      <div
        class="card-body p-0"
        #collapse="ngbCollapse"
        [(ngbCollapse)]="isCollapsed"
      >
        <div
          class="options-list rounded-1"
          cdkDropList
          (cdkDropListDropped)="drop($event)"
        >
          @for (opt of data.questions; track $index) {
          <!-- cdkDragLockAxis="y" -->
          <div cdkDrag class="opt-container">
            <div class="row">
              <div class="col-lg-auto mb-4 pb-2 pb-lg-0 mb-lg-0 pe-lg-0">
                <div class="buttons-action">
                  <button
                    class="btn btn-light border-primary d-none d-lg-inline"
                    cdkDragHandle
                  >
                    <i class="fa-solid fa-bars"></i>
                  </button>

                  <div
                    class="btn-group-vertical d-lg-none"
                    role="group"
                    aria-label="Vertical button group"
                  >
                    @if ($index > 0) {
                    <button
                      type="button"
                      class="btn btn-light border-primary"
                      (click)="raiseQs($index)"
                    >
                      <i class="fa-solid fa-arrow-up"></i>
                    </button>
                    } @if ($index < data.questions.length - 1 ) {
                    <button
                      type="button"
                      class="btn btn-light border-primary"
                      (click)="lowerQs($index)"
                    >
                      <i class="fa-solid fa-arrow-down"></i>
                    </button>
                    }
                  </div>
                </div>
              </div>

              <div class="col-lg mb-3 mb-lg-0">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Título</mat-label>
                  <input
                    name="option-title"
                    matInput
                    [(ngModel)]="data.questions[$index]"
                  />
                </mat-form-field>
              </div>

              <div class="col-lg-auto">
                <button
                  type="button"
                  class="btn btn-danger"
                  (click)="deleteOption($index)"
                >
                  <i class="fa-solid fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
          }

          <div class="row">
            <div class="col-12 my-4 text-end">
              <button
                type="button"
                class="btn btn-warning border-dark"
                (click)="addOption()"
              >
                <i class="fa-solid fa-plus me-2"></i>

                Añadir
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  }
</div>
