import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';

import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { Observable } from 'rxjs';

import { AlertComponent } from '@shared/components/alert/alert.component';
import { AutofocusDirective } from '@shared/directives/autofocus.directive';

import { CrudFormComponent } from '@admin/components/crud-form/crud-form.component';
import { CrudFormFooterComponent } from '@admin/components/crud-form-footer/crud-form-footer.component';
import { CrudFormHeaderComponent } from '@admin/components/crud-form-header/crud-form-header.component';
import { DateControllerComponent } from '@admin/components/date-controller/date-controller.component';
import { PollQuestionComponent } from '@admin/components/poll-question/poll-question.component';
import { PollQuestion } from '@admin/models/PollQuestion.model';
import { ImageControllerComponent } from '@admin/components/image-controller/image-controller.component';

@Component({
  selector: 'app-polls-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    MatInputModule,
    MatListModule,
    MatIconModule,

    AutofocusDirective,

    CdkDrag,
    CdkDragHandle,
    CdkDropList,

    AlertComponent,
    CrudFormHeaderComponent,
    CrudFormFooterComponent,
    DateControllerComponent,
    ImageControllerComponent,
    PollQuestionComponent,
  ],
  templateUrl: './polls-form.component.html',
  styleUrl: './polls-form.component.scss',
})
export class PollsFormComponent extends CrudFormComponent {
  resources: string[] = [];
  types: string[] = [];
  questions: PollQuestion[] = [];

  override services: Observable<any>[] = [
    this.api.call('admin/catalogs/polls/types'),
    this.api.call('admin/catalogs/articles/resource-types'),
  ];
  override data = this.formBuilder.group({
    name: ['', [Validators.required]],
    description: ['', [Validators.required]],
    image: ['', [Validators.required]],
    start_at: [''],
    end_at: [''],
    active: [true, Validators.required],
  });

  @ViewChild(PollQuestionComponent, { static: false })
  qs!: PollQuestionComponent;

  override handleLoadResponse(responses: any[]): void {
    if (responses[0] && responses[0].status === 200)
      this.types = responses[0].data;

    if (responses[1] && responses[1].status === 200)
      this.resources = responses[1].data;

    if (
      this.dataId() !== -1 &&
      responses[responses.length - 1] &&
      responses[responses.length - 1].status === 200
    ) {
      const responseData = responses[responses.length - 1].data;

      this.data.patchValue(responseData);

      if (
        this.data.get('image')?.value &&
        typeof this.data.get('image')?.value === 'string'
      ) {
        this.currentTemplate.file = String(this.data.get('image')?.value ?? '');
      }

      if (responseData['questions']) {
        this.questions = responseData['questions'];
      }
    }

    if (this.questions.length === 0) {
      this.addQuestion();
    }
  }

  override getParams() {
    this.clean();

    const params: any = this.data.value;
    params['active'] = Number(params['active']);

    if (this.eventId() !== -1) {
      params['event_id'] = this.eventId();
    }

    params['questions'] = this.questions;

    return params;
  }

  override handleFile() {
    if (this.currentTemplate.file) {
      this.data.get('image')?.setValue(this.currentTemplate.file);
    }
  }

  addQuestion() {
    const nQ = new PollQuestion();
    this.questions.push(nQ);
  }

  deleteQuestion(index: number) {
    if (!this.questions) return;

    this.questions.splice(index, 1);
  }

  /* 
    ! Clean empty questions and options
  */
  clean() {
    this.questions = this.questions.filter((qs) => {
      if (qs.questions) {
        qs.questions = qs.questions.filter((opt) => opt !== '');
      }
      return qs.title !== '' && qs.type !== '';
    });
  }

  drop(event: CdkDragDrop<PollQuestion[]>) {
    moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
  }

  raiseQs(index: number) {
    if (index >= 0) {
      const currentQuestion = this.questions[index];
      const nextQuestion = this.questions[index - 1];

      this.questions[index] = nextQuestion;
      this.questions[index - 1] = currentQuestion;
    }
  }

  lowerQs(index: number) {
    if (index < this.questions.length - 1) {
      const currentQuestion = this.questions[index];
      const previousQuestion = this.questions[index + 1];

      this.questions[index] = previousQuestion;
      this.questions[index + 1] = currentQuestion;
    }
  }
}
