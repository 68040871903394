<header class="header">
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <button
        class="btn btn-light d-lg-none"
        type="button"
        aria-label="Abrir/cerrar barra lateral"
        (click)="open()"
      >
        <i class="fa-solid fa-bars fa-xl"></i>
      </button>

      <button
        class="navbar-toggler"
        type="button"
        aria-label="Abrir/cerrar barra lateral"
        (click)="isMenuCollapsed = !isMenuCollapsed"
      >
        &#9776;
      </button>

      <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
        <ul class="navbar-nav align-items-center w-100">
          <li class="nav-item">
            <a
              mat-button
              class="px-4"
              routerLink="/"
              (click)="isMenuCollapsed = true"
            >
              <i class="fa-solid fa-house me-2"></i>
              {{ currentEvent().name }}
            </a>
          </li>

          <li class="nav-item ms-auto">
            <button
              class="text-light px-4"
              mat-button
              [matMenuTriggerFor]="menu"
            >
              @if(currentEvent().name) {
              <span class="d-flex align-items-center py-3">
                {{ currentEvent().name }}

                <mat-icon class="ms-2">arrow_drop_down</mat-icon>
              </span>
              }
            </button>

            <mat-menu #menu="matMenu">
              @if (canShow || canCreate) {
              <div class="border border-bottom mb-3 pb-3">
                @if (canShow) {
                <a mat-menu-item routerLink="/admin/events">
                  <mat-icon>format_list_bulleted</mat-icon>

                  Todos
                </a>
                } @if (canCreate) {
                <a mat-menu-item routerLink="/admin/events/create">
                  <mat-icon>add</mat-icon>

                  Agregar
                </a>
                }
              </div>
              } @for (item of data.rows; track $index) {
              <a mat-menu-item [routerLink]="['/' + item.id + '/admin/']">
                {{ item.name }}
              </a>
              }

              <div>
                <button mat-icon-button color="primary">
                  <mat-icon>keyboard_arrow_left</mat-icon>
                </button>

                <button mat-icon-button color="primary">
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
              </div>
            </mat-menu>
          </li>

          <li class="nav-item ms-2">
            @if (logged()) {
            <button mat-raised-button class="px-4" (click)="logout()">
              Salir

              <mat-icon>logout</mat-icon>
            </button>
            } @else {
            <a
              class="nav-link"
              routerLink="/auth/login"
              (click)="isMenuCollapsed = true"
            >
              Login
            </a>
            }
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
