import { Main } from '@shared/models/Main.model';

export class EventModel extends Main {
  id: number = -1;
  active: number = 0;
  enterprise_id: number = -1;
  image: string = '';
  location: string = '';
  name: string = '';
  primary: number = 0;
  sections: Section[] = [];
  start_at: string | null = null;
  end_at: string | null = null;
  created_at: string | null = null;
  deleted_at: string | null = null;
  theme: Theme | null = null;
  timezone: string = '';
  updated_at: string | null = null;
  uuid: string = '';

  getData() {
    const data = this;
    return data;
  }

  override setData(data: any) {
    for (const key in this) {
      if (data[key] !== undefined) {
        this[key] = data[key];
      }
    }
  }
}

export class Section extends Main {
  name: string = '';
  type: string = '';
  icon: string = '';
  start_at: string | null = null;
  end_at: string | null = null;
  collapsable: boolean = false;
  children?: Section[] = [];

  getData() {
    const data = this;

    return data;
  }

  override setData(data: any) {
    for (const key in this) {
      if (data[key] !== undefined) {
        this[key] = data[key];
      }
    }
  }
}

export class Theme {
  color: Color | null = null;
  font: string = '';
}

export class Color {
  primary: string = '';
  secondary: string = '';
  tertiary: string = '';
}
