<div id="crud-footer" class="row justify-content-end">
  <div class="col-lg-6 col-xl-4 col-xxl-3 mb-3 mb-lg-0">
    <button
      type="button"
      mat-raised-button
      class="border-dark w-100"
      (click)="handleClose()"
      [disabled]="loading"
    >
      {{ disable ? "CERRAR" : created ? "ACEPTAR" : "CANCELAR" }}
    </button>
  </div>

  @if (!created && !disable) {
  <div class="col-lg-6 col-xl-4 col-xxl-3">
    <button
      type="submit"
      mat-raised-button
      class="w-100"
      color="accent"
      [disabled]="loading"
    >
      @if (loading) {
      <span
        class="spinner-border spinner-border-sm me-2"
        aria-hidden="true"
      ></span>
      }
      {{ id ? "GUARDAR" : "CREAR" }}
    </button>
  </div>
  }
</div>
