import {
  CrudComponent,
  CrudHeaderComponent,
  SearchBarComponent,
} from '@admin/components';
import { Redeem } from '@admin/models/Redeem.model';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import { AlertComponent } from '@shared/components/alert/alert.component';
import { ElementModel } from '@shared/models/Element.model';
import { Pagination } from '@shared/models/Pagination.model';

@Component({
  selector: 'app-redeems',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatPaginatorModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    AlertComponent,
    CrudHeaderComponent,
    SearchBarComponent,
  ],
  templateUrl: '../../components/crud/crud.component.html',
  styleUrl: './redeems.component.scss',
})
export class RedeemsComponent extends CrudComponent {
  override title: string = 'Canjes';
  override data: Pagination<Redeem> = new Pagination(Redeem);
  override filters: ElementModel[] = [
    { label: 'Activo', value: '1' },
    { label: 'Inactivo', value: '0' },
  ];

  override handleLoadResponse(responses: any[]): void {
    if (responses[0].data) this.data.setData(responses[0].data);

    this.pagination.set(this.data.rows.length > 0);
    this.stopLoading();
  }

  // override showModal(data: Country): void {
  //   const modalRef = this.modalService.open(CountriesFormComponent, {
  //     size: 'lg',
  //   });

  //   modalRef.componentInstance.dataId.set(data.id);
  //   modalRef.componentInstance.endpoint = 'admin/countries';
  //   modalRef.componentInstance.readonly = true;
  // }
}
