<div class="row w-100">
  <div class="col-12 mb-4">
    <p class="mb-0">{{ label }}</p>
  </div>

  <div class="col-lg-7 mb-4 mb-lg-0">
    <input
      id="image"
      placeholder="avatar"
      class="form-control"
      type="file"
      (change)="selectFile.emit($event)"
      accept="image/png, image/jpeg, .png, .jpg"
      [disabled]="disable"
    />
  </div>

  <div class="col-lg-5 mb-4 mb-lg-0 text-center">
    <img
      [src]="src"
      class="img-fluid img-controller"
      [alt]="alt"
      onerror="this.onerror=null; this.src='assets/images/logo.png'"
    />
  </div>
</div>
