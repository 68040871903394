<div class="row h-100">
  <div class="col-12 px-0 py-2">
    <form class="row">
      <div class="col-lg-auto mb-4 mb-lg-0">
        <button
          class="btn btn-light border-dark d-none d-lg-inline"
          cdkDragHandle
        >
          <i class="fa-solid fa-bars"></i>
        </button>

        <div
          class="btn-group-vertical d-lg-none"
          role="group"
          aria-label="Vertical button group"
        >
          @if (index > 0) {
          <button
            type="button"
            class="btn btn-light border-dark"
            (click)="raiseQs(index)"
          >
            <i class="fa-solid fa-arrow-up"></i>
          </button>
          } @if (index < length - 1 ) {
          <button
            type="button"
            class="btn btn-light border-dark"
            (click)="lowerQs(index)"
          >
            <i class="fa-solid fa-arrow-down"></i>
          </button>
          }
        </div>
      </div>

      <div class="col-lg mb-4 mb-lg-0">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Pregunta</mat-label>

          <input name="question-title" matInput [(ngModel)]="data.title" />
        </mat-form-field>
      </div>

      <div class="col-lg-auto text-end">
        <button
          type="button"
          class="btn btn-danger"
          (click)="delete.emit()"
        >
          <i class="fa-solid fa-trash"></i>
        </button>
      </div>
    </form>
  </div>

  <div class="col-12">
    <div class="card border-dark">
      <div class="card-header bg-primary-subtle">
        <div class="d-flex w-100">
          <h5 class="my-2">Opciones</h5>

          <button
            type="button"
            class="btn btn-primary ms-auto"
            (click)="collapse.toggle()"
            [attr.aria-expanded]="!isCollapsed"
          >
            <i
              class="fa-solid"
              [ngClass]="isCollapsed ? 'fa-arrow-down' : 'fa-arrow-up'"
            ></i>
          </button>
        </div>
      </div>

      <div
        class="card-body p-0"
        #collapse="ngbCollapse"
        [(ngbCollapse)]="isCollapsed"
      >
        <div
          class="options-list rounded-1"
          cdkDropList
          (cdkDropListDropped)="drop($event)"
        >
          @for (opt of data.answers; track $index) {
          <!-- cdkDragLockAxis="y" -->
          <div cdkDrag class="opt-container">
            <div class="row">
              <div class="col-lg-auto mb-4 pb-2 pb-lg-0 mb-lg-0 pe-lg-0">
                <div class="buttons-action">
                  <button
                    class="btn btn-light border-primary d-none d-lg-inline"
                    cdkDragHandle
                  >
                    <i class="fa-solid fa-bars"></i>
                  </button>

                  <div
                    class="btn-group d-lg-none"
                    role="group"
                    aria-label="Button group"
                  >
                    @if ($index > 0) {
                    <button
                      type="button"
                      class="btn btn-light border-primary"
                      (click)="raiseQs($index)"
                    >
                      <i class="fa-solid fa-arrow-up"></i>
                    </button>
                    } @if ($index < data.answers.length - 1 ) {
                    <button
                      type="button"
                      class="btn btn-light border-primary"
                      (click)="lowerQs($index)"
                    >
                      <i class="fa-solid fa-arrow-down"></i>
                    </button>
                    }
                  </div>
                </div>
              </div>

              <div class="col-lg mb-3 mb-lg-0">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Título</mat-label>
                  <input
                    name="option-title"
                    matInput
                    [(ngModel)]="data.answers[$index].title"
                  />
                </mat-form-field>
              </div>

              <div class="col-lg-auto">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Puntaje</mat-label>
                  <input
                    name="option-score"
                    type="number"
                    matInput
                    [(ngModel)]="data.answers[$index].score"
                  />
                </mat-form-field>
              </div>

              <div class="col-auto ms-auto">
                <button
                  type="button"
                  class="btn btn-danger"
                  (click)="deleteOption($index)"
                >
                  <i class="fa-solid fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
          }

          <div class="row">
            <div class="col-12 my-4 text-end">
              <button
                type="button"
                class="btn btn-warning border-dark"
                (click)="addOption()"
              >
                <i class="fa-solid fa-plus me-2"></i>

                Añadir
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
