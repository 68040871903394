<div class="container-fluid py-3 py-lg-4 py-xl-5">
  <app-crud-form-header
    [id]="dataId()"
    title="expositor"
  ></app-crud-form-header>

  <form
    [formGroup]="data"
    (submit)="submit()"
    class="row justify-content-around gy-3 gy-lg-4"
  >
    <div class="col-12">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="name" required />

        @if (data.get('name')?.touched &&
        data.get('name')?.hasError('required')) {
        <mat-error> El nombre es <strong>requerido</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="col-12">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Descripción</mat-label>
        <textarea
          matInput
          placeholder="Descripción"
          formControlName="description"
        ></textarea>

        @if (data.get('description')?.touched &&
        data.get('description')?.hasError('required')) {
        <mat-error> La descripción es <strong>requerida</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="col-12 px-0">
      <app-image-controller
        [src]="currentTemplate.file"
        (selectFile)="selectFile($event)"
      ></app-image-controller>
    </div>

    <div class="col-12 px-0">
      <div class="row">
        <div class="col-12 mb-3"><hr /></div>

        <div class="col-12 mb-4">
          <p class="mb-0">Archivos PDF</p>
        </div>

        <div class="col-xl-7 mb-4 mb-lg-0">
          <input
            id="pdf-selector"
            placeholder="avatar"
            class="form-control"
            type="file"
            (change)="selectDocument($event)"
            accept="application/pdf"
          />
        </div>

        <div class="col-xl-5 px-0">
          <div class="row">
            @for (pdfFile of pdfFiles; track $index) {
            <div class="col-12 mb-3">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Archivo PDF"
                  aria-label="Recipient's username"
                  aria-describedby="current-file"
                  [value]="pdfFile.name"
                  readonly
                />

                <button
                  class="btn btn-danger"
                  type="button"
                  id="current-file"
                  (click)="deletePdf($index)"
                >
                  Eliminar
                </button>
              </div>
            </div>
            }
          </div>
        </div>

        <div class="col-12 mt-3"><hr /></div>
      </div>
    </div>

    <div class="col-lg-6">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Correo</mat-label>
        <input matInput formControlName="email" type="email" required />

        @if (data.get('email')?.touched &&
        data.get('email')?.hasError('required')) {
        <mat-error> El correo es <strong>requerido</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="col-lg-6">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Teléfono</mat-label>
        <input matInput formControlName="phone" type="tel" required />

        @if (data.get('phone')?.touched &&
        data.get('phone')?.hasError('required')) {
        <mat-error> El teléfono es <strong>requerido</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="col-12">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Dirección</mat-label>
        <input matInput formControlName="location" required />

        @if (data.get('location')?.touched &&
        data.get('location')?.hasError('required')) {
        <mat-error> La dirección es <strong>requerido</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="col-12">
      <mat-checkbox formControlName="active">Activo</mat-checkbox>
    </div>

    <app-alert [content]="alertData"></app-alert>

    <div class="col-12 px-0">
      <app-crud-form-footer
        [id]="dataId()"
        [created]="created"
        [loading]="loadingService.getLoading()"
        (cancel)="cancel()"
      ></app-crud-form-footer>
    </div>
  </form>
</div>
