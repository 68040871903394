import { Main } from '@shared/models/Main.model';

export class Poll extends Main {
  id: number = -1;
  name: string = '';
  description: string = '';
  questions: Question[] = [];
  start_at: string | null = null;
  end_at: string | null = null;
  image: string = '';
  event_id: number = 0;
  enterprise_id: number = 0;
  active: number = 0;

  override setData(data: any) {
    for (const key in this) {
      if (data[key] !== undefined) {
        this[key] = data[key];
      }
    }
  }
}

export class Question {
  title: string = '';
  type: string = '';
  uuid: string = '';
  answers?: string[] = [];
}
