import { Main } from '@shared/models/Main.model';
import { DateModel } from './Date.model';

export class ScheduleEvent extends Main {
  id: number = -1;
  name: string = '';
  description: string = '';
  dates: DateModel[] = [];
  image: string = '';
  event_id: number = 1;
  enterprise_id: number = 1;
  active: number = 1;
}
