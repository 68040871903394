<div class="container-fluid py-3 py-lg-4 py-xl-5">
  <app-crud-form-header [id]="dataId()" title="rol"></app-crud-form-header>

  <form
    [formGroup]="data"
    (submit)="submit()"
    class="row gy-3 gy-lg-4"
  >
    <div class="col-12">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="name" required />

        @if (data.get('name')?.touched &&
        data.get('name')?.hasError('required')) {
        <mat-error> El Nombre es <strong>requerido</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    @for (permission of permissions; track $index) {
    <div class="col-lg-6 col-xl-4 col-xxl-3">
      <div class="form-check">
        <input
          [id]="'permission-' + permission.id"
          class="form-check-input"
          type="checkbox"
          [value]="permission.id"
          (click)="handleId(permission.id)"
          [checked]="ids.has(permission.id)"
        />
        <!-- [checked]="data.get('permissions')?.value?.includes(permission.id)" -->

        <label class="form-check-label" [for]="'permission-' + permission.id">
          {{ permission.name }}
        </label>
      </div>
    </div>
    }

    <app-alert [content]="alertData"></app-alert>

    <div class="w-100 mt-0"></div>

    <div class="col-12 px-0">
      <app-crud-form-footer
        [id]="dataId()"
        [created]="created"
        [loading]="loadingService.getLoading()"
        (cancel)="cancel()"
      ></app-crud-form-footer>
    </div>
  </form>
</div>
