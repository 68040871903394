<div class="container-fluid py-3 py-lg-4">
  <app-crud-header
    titleContainer="container"
    title="Términos y Condiciones"
  ></app-crud-header>

  <div class="container py-4 px-0">
    <div class="row">
      <div class="col-12">
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Blanditiis
          quasi excepturi quibusdam voluptatem est assumenda quas modi,
          asperiores cumque, itaque repellat sit provident omnis beatae expedita
          mollitia doloribus ipsum eveniet? Lorem ipsum dolor sit amet
          consectetur, adipisicing elit. Blanditiis quasi excepturi quibusdam
          voluptatem est assumenda quas modi, asperiores cumque, itaque repellat
          sit provident omnis beatae expedita mollitia doloribus ipsum eveniet?
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Blanditiis
          quasi excepturi quibusdam voluptatem est assumenda quas modi,
          asperiores cumque, itaque repellat sit provident omnis beatae expedita
          mollitia doloribus ipsum eveniet? Lorem ipsum dolor sit amet
          consectetur, adipisicing elit. Blanditiis quasi excepturi quibusdam
          voluptatem est assumenda quas modi, asperiores cumque, itaque repellat
          sit provident omnis beatae expedita mollitia doloribus ipsum eveniet?
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Blanditiis
          quasi excepturi quibusdam voluptatem est assumenda quas modi,
          asperiores cumque, itaque repellat sit provident omnis beatae expedita
          mollitia doloribus ipsum eveniet? Lorem ipsum dolor sit amet
          consectetur, adipisicing elit. Blanditiis quasi excepturi quibusdam
          voluptatem est assumenda quas modi, asperiores cumque, itaque repellat
          sit provident omnis beatae expedita mollitia doloribus ipsum eveniet?
        </p>
      </div>
    </div>
  </div>
</div>
