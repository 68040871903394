import { Main } from '@shared/models/Main.model';

export class Redeem extends Main {
  id: number = -1;
  uuid: string = '';
  coupon: string = '';
  username: string = '';
  email: string = '';
  created_at: string | null = null;
  redeemed_at: string | null = null;
  [key: string]: any;
}
