import { Main } from '@shared/models/Main.model';

export class Tag extends Main {
  id: number = -1;
  name: string = '';
  entity: string = '';
  event_id: number = 0;
  enterprise_id: number = 0;
  active: number = 0;
  [key: string]: any;
}
