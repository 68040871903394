import { Main } from '@shared/models/Main.model';

export class Article extends Main {
  id: number = -1;
  name: string = '';
  type: string = '';
  resource_type: string = '';
  resource_content: string = '';
  start_at: string | null = '';
  end_at: string | null = '';
  image: string = '';
  event_id: number = -1;
  enterprise_id: number = -1;
  active: number = 1;
  tags: number[] = [];
}
