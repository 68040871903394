import { Injectable, WritableSignal, signal } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, of, tap } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

const apiUrl = 'admin/profile/me';
const rolesKey = 'roles';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  roles: WritableSignal<Set<string>> = signal(new Set([]));

  constructor(
    private api: ApiService,
    private sorageService: LocalStorageService
  ) {
    this.initialize();
  }

  private async initialize() {
    try {
      await this.getRoles(true).toPromise();
    } catch (error) {
      console.error(error);
    }
  }

  getRoles(init: boolean = false): Observable<Set<string>> {
    if (!init) {
      if (this.roles().size > 0) {
        return of(this.roles());
      }

      const roles = new Set(this.sorageService.get<string[]>(rolesKey));

      if (roles) {
        this.roles.set(roles);
        return of(roles);
      }
    }

    return this.api.call(apiUrl).pipe(
      tap((response: any) => {
        if (response.status === 200) {
          this.sorageService.set(rolesKey, response.data['permissions']);
          this.roles.set(new Set(response.data['permissions']));
        }
      })
    );
  }

  getUserRoles(): Set<string> {
    if (this.roles().size > 0) {
      return this.roles();
    }

    const roles = new Set(this.sorageService.get<string[]>(rolesKey));

    return roles;
  }

  can(group: string, permission: string): boolean {
    console.log(group + '.' + permission);

    return this.roles().has(group + '.' + permission);
  }
}
