<footer class="footer">
  <div class="row justify-content-center text-center m-0">
    <div class="col-auto">
      <a
        [routerLink]="['/admin/aviso-de-privacidad']"
        target="_blank"
        rel="noopener noreferrer"
      >
        Aviso de Privacidad
      </a>
    </div>

    <div class="col-auto">|</div>

    <div class="col-auto">
      <a
        [routerLink]="['/admin/terminos-y-condiciones']"
        target="_blank"
        rel="noopener noreferrer"
      >
        Términos y Condiciones
      </a>
    </div>

    <div class="col-auto">|</div>

    <div class="col-auto">
      <span>
        BASF © 2024
      </span>
    </div>
  </div>
</footer>
