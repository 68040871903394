import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

import { AlertComponent } from '@shared/components/alert/alert.component';

import {
  CrudFormComponent,
  CrudFormFooterComponent,
  CrudFormHeaderComponent,
} from '@admin/components';

@Component({
  selector: 'app-countries-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
    AlertComponent,
    CrudFormHeaderComponent,
    CrudFormFooterComponent,
  ],
  templateUrl: './countries-form.component.html',
  styleUrl: './countries-form.component.scss',
})
export class CountriesFormComponent extends CrudFormComponent {
  resources: string[] = [];
  types: string[] = [];

  override data = this.formBuilder.group({
    name: ['', [Validators.required]],
    code: ['', [Validators.required]],
  });

  override handleLoadResponse(responses: any[]): void {
    if (this.dataId() !== -1 && responses[0] && responses[0].status === 200) {
      const responseData = responses[0].data;
      this.data.patchValue(responseData);
    }

    if (this.readonly) this.data.disable();
  }

  override getParams() {
    const params = this.data.value;

    return params;
  }
}
