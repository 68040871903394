<div class="container-fluid py-3 py-lg-4 py-xl-5">
  <app-crud-form-header [id]="dataId()" title="evento"></app-crud-form-header>

  <form
    [formGroup]="data"
    (submit)="submit()"
    class="row justify-content-around gy-3 gy-lg-4"
  >
    <div class="col-12">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Nombre del Evento</mat-label>
        <input matInput formControlName="name" required />

        @if (data.get('name')?.touched &&
        data.get('name')?.hasError('required')) {
        <mat-error>Nombre del evento es <strong>requerido</strong></mat-error>
        }
      </mat-form-field>
    </div>

    <div class="col-12">
      <ng-select
        id="timeZone"
        appearance="outline"
        [items]="timeZones"
        placeholder="Zona Horaria"
        [loading]="loadingService.loader()"
        loadingText="Cargando..."
        [clearable]="false"
        formControlName="timezone"
      >
      </ng-select>

      @if (data.get('timezone')?.touched &&
      data.get('timezone')?.hasError('required')) {
      <div id="timeZoneValidation" class="invalid-feedback">
        La Zona Horaria es <strong>requerida</strong>
      </div>
      }
    </div>

    <div class="col-12">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Ubicación</mat-label>
        <input matInput formControlName="location" required />

        @if (data.get('location')?.touched &&
        data.get('location')?.hasError('required')) {
        <mat-error>La ubicación es <strong>requerido</strong></mat-error>
        }
      </mat-form-field>
    </div>

    <div class="col-lg-4">
      <label for="primaryColor" class="form-label">
        Color primario
        <strong>{{ data.get("primary")?.value }}</strong>
      </label>

      <input
        id="primaryColor"
        type="text"
        class="form-control-lg w-100"
        [value]="data.get('primary')?.value"
        [colorPicker]="data.get('primary')?.value ?? ''"
        [style.background]="data.get('primary')?.value ?? ''"
        (colorPickerChange)="data.get('primary')?.setValue($event)"
      />
      <!-- [(colorPicker)]="data.get('theme')?.value['secondary']"
        (colorPickerChange)="setColor('secondary', $event)" -->

      @if (data.get('primary')?.touched &&
      data.get('primary')?.hasError('required')) {
      <mat-error>El color primario es <strong>requerido</strong></mat-error>
      }
    </div>

    <div class="col-lg-4">
      <label for="secondaryColor" class="form-label">
        Color secundario
        <strong>
          {{ data.get("secondary")?.value }}
        </strong>
      </label>

      <input
        id="secondaryColor"
        type="text"
        class="form-control-lg w-100"
        [value]="data.get('secondary')?.value"
        [colorPicker]="data.get('secondary')?.value ?? ''"
        [style.background]="data.get('secondary')?.value ?? ''"
        (colorPickerChange)="data.get('secondary')?.setValue($event)"
      />

      @if (data.get('secondary')?.touched &&
      data.get('secondary')?.hasError('required')) {
      <mat-error>El color secundario es <strong>requerido</strong></mat-error>
      }
    </div>

    <div class="col-lg-4">
      <label for="tertiaryColor" class="form-label">
        Color terciario
        <strong>{{ data.get("tertiary")?.value }}</strong>
      </label>

      <input
        id="tertiaryColor"
        type="text"
        class="form-control-lg w-100"
        [value]="data.get('tertiary')?.value"
        [colorPicker]="data.get('tertiary')?.value ?? ''"
        [style.background]="data.get('tertiary')?.value ?? ''"
        (colorPickerChange)="data.get('tertiary')?.setValue($event)"
      />

      @if (data.get('tertiary')?.touched &&
      data.get('tertiary')?.hasError('required')) {
      <mat-error>El color terciario es <strong>requerido</strong></mat-error>
      }
    </div>

    <div class="col-12">
      <label for="fonts" class="form-label">Fuente</label>

      <ng-select
        id="fonts"
        appearance="outline"
        [items]="fonts"
        formControlName="font"
        placeholder="Selecciona la fuente"
        [loading]="fonts.length === 0"
        loadingText="Cargando..."
        [clearable]="false"
      >
      </ng-select>

      @if (data.get('font')?.touched && data.get('font')?.hasError('required'))
      {
      <mat-error>La fuente es <strong>requerido</strong></mat-error>
      }
    </div>

    @if (initialized) {
    <div class="col-12 px-0">
      <app-date-controller
        [startDate]="data.get('start_at')?.value"
        [endDate]="data.get('end_at')?.value"
        (startDateChange)="data.get('start_at')?.setValue($event)"
        (endDateChange)="data.get('end_at')?.setValue($event)"
      ></app-date-controller>
    </div>
    }

    <div class="w-100"></div>

    <hr />

    <div class="col-lg my-3">
      <p class="h4 mb-0">Secciones</p>
    </div>

    <div class="col-lg-auto my-3">
      <button type="button" class="btn btn-primary" (click)="addSection()">
        Nueva sección <i class="fa-solid fa-circle-plus ms-1 fa-lg"></i>
      </button>
    </div>

    @for (section of sections; track $index) {
    <div class="col-12">
      <app-event-section
        [section]="section"
        [fonts]="fonts"
        [sections]="allSections"
        (onDelete)="deleteSection($index)"
      ></app-event-section>
    </div>
    }

    <app-alert [content]="alertData"></app-alert>

    <div class="col-12 px-0">
      <app-crud-form-footer
        [id]="dataId()"
        [created]="created"
        [loading]="loadingService.getLoading()"
        (cancel)="cancel()"
      ></app-crud-form-footer>
    </div>
  </form>
</div>
