import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';

import { QRCodeModule } from 'angularx-qrcode';
import html2canvas from 'html2canvas';

import { AlertComponent } from '@shared/components/alert/alert.component';
import { ElementModel } from '@shared/models/Element.model';
import { Pagination } from '@shared/models/Pagination.model';

import { CrudComponent } from '@admin/components/crud/crud.component';
import { EventModel } from '@admin/models/Event.model';
import {
  Search,
  SearchBarComponent,
} from '@admin/components/search-bar/search-bar.component';
import { CrudHeaderComponent } from '@admin/components/crud-header/crud-header.component';

@Component({
  selector: 'app-events',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatPaginatorModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    QRCodeModule,
    AlertComponent,
    CrudHeaderComponent,
    SearchBarComponent,
  ],
  templateUrl: './events.component.html',
  styleUrl: './events.component.scss',
})
export class EventsComponent extends CrudComponent {
  override data: Pagination<EventModel> = new Pagination(EventModel);
  override filters: ElementModel[] = [
    { label: 'Activo', value: '1' },
    { label: 'Inactivo', value: '0' },
  ];
  override params = {
    take: 12,
    page: 1,
    search: '',
    is_open: 0,
  };

  override handleLoadResponse(responses: any[]): void {
    this.data.setData(responses[0].data);
    this.data.rows.forEach((row) => {
      row.uuid = row.uuid.split(':')[1] ?? row.uuid;
    });
    this.stopLoading();
  }

  override handleSearch(search: Search): void {
    this.params.search = search.term;
    this.params.is_open = +search.filter;

    this.load();
  }

  download(code: HTMLDivElement) {
    let imageToDownload;

    html2canvas(code).then((canvas) => {
      imageToDownload = canvas.toDataURL('image/png', 1);
      const a = document.createElement('a');
      a.href = imageToDownload;
      a.download = 'nuevas-sensaciones-codigo-code.png';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  }
}
