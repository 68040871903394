import { CrudFormFooterComponent } from '@admin/components/crud-form-footer/crud-form-footer.component';
import { CrudFormHeaderComponent } from '@admin/components/crud-form-header/crud-form-header.component';
import { CrudFormComponent } from '@admin/components/crud-form/crud-form.component';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { AlertComponent } from '@shared/components/alert/alert.component';

@Component({
  selector: 'app-permissions-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
    AlertComponent,
    CrudFormHeaderComponent,
    CrudFormFooterComponent,
  ],
  templateUrl: './permissions-form.component.html',
  styleUrl: './permissions-form.component.scss',
})
export class PermissionsFormComponent extends CrudFormComponent {
  resources: string[] = [];
  types: string[] = [];

  override data = this.formBuilder.group({
    name: ['', [Validators.required]],
    code: ['', [Validators.required]],
  });

  override handleLoadResponse(responses: any[]): void {
    if (this.dataId() !== -1 && responses[0] && responses[0].status === 200) {
      const responseData = responses[0].data;
      this.data.patchValue(responseData);
    }
  }

  override getParams() {
    const params = this.data.value;

    return params;
  }

  override formatEndpoint(): void {
    this.endpoint = 'admin/' + this.endpoint;
  }
}
