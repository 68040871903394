<div class="card">
  <div class="card-header text-bg-primary py-3">
    <div class="d-flex align-items-center w-100">
      <p class="h5 mb-0">
        Sección @if (section.collapsable) {
        <span>{{ section.type }}</span>
        }
      </p>

      <button
        type="button"
        class="btn btn-light ms-auto"
        (click)="section.collapsable = !section.collapsable"
      >
        <i
          class="fa-solid fa-lg text-primary"
          [ngClass]="section.collapsable ? 'fa-circle-down' : 'fa-circle-up'"
        ></i>
      </button>
    </div>
  </div>

  <div [(ngbCollapse)]="section.collapsable" class="card-body px-0">
    <div class="row justify-content-around gy-2 gy-lg-3 py-3">
      <div class="col-12">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Nombre</mat-label>

          <input matInput [(ngModel)]="section.name" required />
        </mat-form-field>
      </div>

      <div class="col-12">
        <ng-select
          id="sections"
          appearance="outline"
          [items]="sections"
          placeholder="Selecciona la fuente"
          [loading]="fonts.length === 0"
          loadingText="Cargando..."
          [clearable]="false"
          [(ngModel)]="section.type"
        >
        </ng-select>
      </div>

      <div class="col-12">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Icono</mat-label>
          <input matInput [(ngModel)]="section.icon" required />
        </mat-form-field>
      </div>

      <div class="col-12">
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="date-controller"
            [(ngModel)]="hasDates"
          />

          <label class="form-check-label" for="date-controller">
            Agregar fechas
          </label>
        </div>
      </div>

      
      <div
        class="col-12 px-0"
        #collapse="ngbCollapse"
        [(ngbCollapse)]="!hasDates"
      >
        <app-date-controller
          [startDate]="section.start_at"
          [endDate]="section.end_at"
          (startDateChange)="handleDate($event)"
          (endDateChange)="handleDate($event, true)"
        ></app-date-controller>
      </div>

      <div class="col-12 text-end">
        <button type="button" class="btn btn-danger" (click)="delete()">
          Eliminar

          <i class="fa-solid fa-trash ms-2"></i>
        </button>
      </div>
    </div>
  </div>
</div>
