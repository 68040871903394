import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';

import { AlertComponent } from '@shared/components/alert/alert.component';

import { CrudFormFooterComponent } from '@admin/components/crud-form-footer/crud-form-footer.component';
import { CrudFormHeaderComponent } from '@admin/components/crud-form-header/crud-form-header.component';
import { CrudFormComponent } from '@admin/components/crud-form/crud-form.component';
import { Country } from '@admin/models/Country.model';
import { Observable } from 'rxjs';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-states-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    MatInputModule,

    AlertComponent,
    CrudFormHeaderComponent,
    CrudFormFooterComponent,
  ],
  templateUrl: './states-form.component.html',
  styleUrl: './states-form.component.scss',
})
export class StatesFormComponent extends CrudFormComponent {
  countries: Country[] = [];

  override services: Observable<any>[] = [
    this.api.call('admin/countries?limit=1000&page=1'),
  ];

  override data = this.formBuilder.group({
    name: ['', [Validators.required]],
    country_id: ['', [Validators.required]],
  });

  override handleLoadResponse(responses: any[]): void {
    if (responses[0] && responses[0].status === 200)
      this.countries = responses[0].data.rows;

    if (this.dataId() !== -1 && responses[1] && responses[1].status === 200) {
      const responseData = responses[1].data;
      this.data.patchValue(responseData);
    }

    if (this.readonly) this.data.disable();
  }

  override getParams() {
    const params = this.data.value;

    return params;
  }
}
