import { CrudFormFooterComponent } from '@admin/components/crud-form-footer/crud-form-footer.component';
import { CrudFormHeaderComponent } from '@admin/components/crud-form-header/crud-form-header.component';
import { CrudFormComponent } from '@admin/components/crud-form/crud-form.component';
import { RolePermission } from '@admin/models/Role.model';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { AlertComponent } from '@shared/components/alert/alert.component';
import { AutofocusDirective } from '@shared/directives/autofocus.directive';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-roles-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,

    AutofocusDirective,

    AlertComponent,
    CrudFormHeaderComponent,
    CrudFormFooterComponent,
  ],
  templateUrl: './roles-form.component.html',
  styleUrl: './roles-form.component.scss',
})
export class RolesFormComponent extends CrudFormComponent {
  permissions: RolePermission[] = [];
  ids: Set<number> = new Set<number>();

  override data = this.formBuilder.group({
    name: ['', [Validators.required]],
  });
  override services: Observable<any>[] = [
    this.api.call('admin/permissions?limit=10000'),
  ];

  override handleLoadResponse(responses: any[]): void {
    if (responses[0] && responses[0].status === 200)
      this.permissions = responses[0].data.rows.map((role: any) => {
        const nRole = new RolePermission();
        nRole.setData(role);
        return nRole;
      });

    if (
      this.dataId() !== -1 &&
      responses[responses.length - 1] &&
      responses[responses.length - 1].status === 200
    ) {
      const responseData = responses[responses.length - 1].data;
      this.data.patchValue(responseData);

      if (responseData['permissions']) {
        responseData['permissions']?.forEach((roleId: RolePermission) => {
          this.ids.add(roleId.id);
        });
      }
    }
  }

  handleId(id: number) {
    if (this.ids.has(id)) {
      this.ids.delete(id);
    } else {
      this.ids.add(id);
    }
  }

  override getParams() {
    const params: any = this.data.value;
    params['permissions'] = [...this.ids];

    return params;
  }

  override success(response: any): void {
    this.handleAlert('¡Cambios guardados correctamente!', 'success');
    this.stopLoading();
  }

  override isValid(): boolean {
    this.data.markAllAsTouched();

    let valid: boolean = this.data.valid ?? false;

    if (!valid) {
      this.handleAlert('¡Llena todos los campos requeridos!');
    }

    if (this.ids.size === 0) {
      this.handleAlert('¡Selecciona al menos un permiso!');

      valid = false;
    }

    return valid;
  }

  override formatEndpoint(): void {
    this.endpoint = 'admin/' + this.endpoint;
  }
}
