import { Main } from '@shared/models/Main.model';

export class Notification extends Main {
  id: number = -1;
  name: string = '';
  body: string = '';
  sent: number = 0;
  current: number = 0;
  start_at: string | null = null;
  completed_at: string | null = null;
  event_id: number = 0;
  enterprise_id: number = 0;
  active: number = 0;

  override setData(data: any) {
    for (const key in this) {
      if (data[key] !== undefined) {
        this[key] = data[key];
      }
    }
  }
}
