import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { MatInputModule } from '@angular/material/input';
import { AlertComponent } from '@shared/components/alert/alert.component';
import { Observable } from 'rxjs';

import {
  CrudFormComponent,
  CrudFormFooterComponent,
  CrudFormHeaderComponent,
  ImageControllerComponent,
} from '@admin/components';

@Component({
  selector: 'app-enterprises-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,

    AlertComponent,
    CrudFormHeaderComponent,
    CrudFormFooterComponent,
    ImageControllerComponent,
  ],
  templateUrl: './enterprises-form.component.html',
  styleUrl: './enterprises-form.component.scss',
})
export class EnterprisesFormComponent extends CrudFormComponent {
  override services: Observable<any>[] = [
    this.api.call('admin/catalogs/articles/types'),
    this.api.call('admin/catalogs/articles/resource-types'),
  ];

  override data = this.formBuilder.group({
    name: ['', [Validators.required]],
    image: ['', [Validators.required]],
    address: ['', [Validators.required]],
    phone: ['', [Validators.required]],
  });

  override handleLoadResponse(responses: any[]): void {
    if (
      this.dataId() !== -1 &&
      responses[responses.length - 1] &&
      responses[responses.length - 1].status === 200
    ) {
      const responseData = responses[responses.length - 1].data;
      this.data.patchValue(responseData);

      if (
        this.data.get('image')?.value &&
        typeof this.data.get('image')?.value === 'string'
      ) {
        this.currentTemplate.file = String(this.data.get('image')?.value ?? '');
      }
    }
  }

  override getParams() {
    const params: any = this.data.value;

    if (this.eventId() !== -1) {
      params['event_id'] = this.eventId();
    }

    return params;
  }

  override handleFile() {
    if (this.currentTemplate.file) {
      this.data.get('image')?.setValue(this.currentTemplate.file);
    }
  }
}
