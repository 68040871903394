<div class="container-fluid py-3 py-lg-4 py-xl-5">
  <app-crud-form-header [id]="dataId()" title="artículo"></app-crud-form-header>

  <form
    [formGroup]="data"
    (submit)="submit()"
    class="row justify-content-around gy-3 gy-lg-4"
  >
    <div class="col-xl-5">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Título</mat-label>
        <input matInput formControlName="name" required />

        @if (data.get('name')?.touched &&
        data.get('name')?.hasError('required')) {
        <mat-error> El título es <strong>requerido</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="col-xl-7 px-0">
      <app-image-controller
        label="Avatar"
        [src]="currentTemplate.file"
        (selectFile)="selectFile($event)"
      ></app-image-controller>
    </div>

    <div class="col-12">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Tipo de evento</mat-label>
        <mat-select formControlName="type">
          @for (type of types; track $index) {
          <mat-option [value]="type">{{ type }}</mat-option>
          }
        </mat-select>

        @if (data.get('type')?.touched &&
        data.get('type')?.hasError('required')) {
        <mat-error> El tipo de evento es <strong>requerido</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="col-12">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Tipo de recurso</mat-label>
        <mat-select formControlName="resource_type">
          @for (resource of resources; track $index) {
          <mat-option [value]="resource">{{ resource }}</mat-option>
          }
        </mat-select>

        @if (data.get('resource_type')?.touched &&
        data.get('resource_type')?.hasError('required')) {
        <mat-error>
          El tipo de recurso es <strong>requerido</strong>
        </mat-error>
        }
      </mat-form-field>
    </div>

    @if (data.get('resource_type')?.value) {
    <div class="col-12">
      @if (data.get('resource_type')!.value === "html") {
      <angular-editor
        formControlName="resource_content"
        [config]="editorConfig"
      ></angular-editor>
      } @else {
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Contenido</mat-label>

        <input
          matInput
          formControlName="resource_content"
          type="text"
          required
        />
      </mat-form-field>
      }
    </div>
    } @if (initialized) {
    <div class="col-12 px-0">
      <app-date-controller
        [startDate]="data.get('start_at')?.value"
        [endDate]="data.get('end_at')?.value"
        (startDateChange)="data.get('start_at')?.setValue($event)"
        (endDateChange)="data.get('end_at')?.setValue($event)"
      ></app-date-controller>
    </div>
    }

    <div class="col-12">
      <mat-checkbox formControlName="active">Activo</mat-checkbox>
    </div>

    <app-alert [content]="alertData"></app-alert>

    <div class="w-100 mt-0"></div>

    <div class="col-12 px-0">
      <app-crud-form-footer
        [id]="dataId()"
        [created]="created"
        [loading]="loadingService.getLoading()"
        (cancel)="cancel()"
      ></app-crud-form-footer>
    </div>
  </form>
</div>
