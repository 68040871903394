<div class="container-fluid py-3 py-lg-4 py-xl-5">
  <app-crud-form-header
    [id]="dataId()"
    [disable]="readonly"
    title="usuario"
  ></app-crud-form-header>

  <form
    [formGroup]="data"
    (submit)="submit()"
    class="row justify-content-around gy-3 gy-lg-4"
  >
    <div class="col-12 px-0">
      <app-image-controller
        [src]="currentTemplate.file"
        [disable]="readonly"
        (selectFile)="selectFile($event)"
      ></app-image-controller>
    </div>

    <div class="col-12">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="name" required />

        @if (data.get('name')?.touched &&
        data.get('name')?.hasError('required')) {
        <mat-error> El Nombre es <strong>requerido</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="col-12">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Apellido(s)</mat-label>
        <input matInput formControlName="name" required />

        @if (data.get('name')?.touched &&
        data.get('name')?.hasError('required')) {
        <mat-error> El Apellido(s) es <strong>requerido</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="col-12">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Correo</mat-label>
        <input matInput formControlName="name" required />

        @if (data.get('name')?.touched &&
        data.get('name')?.hasError('required')) {
        <mat-error> El Correo es <strong>requerido</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    <!-- <div class="col-12">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Contraseña</mat-label>
        <input matInput formControlName="password" required />

        @if (data.get('password')?.touched &&
        data.get('password')?.hasError('required')) {
        <mat-error> El Contraseña es <strong>requerido</strong> </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="col-12">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Confirmar contraseña</mat-label>
        <input type="password" matInput formControlName="confirm" required />

        @if (data.get('confirm')?.touched &&
        data.get('confirm')?.hasError('required')) {
        <mat-error>
          El Confirmar contraseña es <strong>requerido</strong>
        </mat-error>
        }
      </mat-form-field>
    </div> -->

    <app-alert [content]="alertData"></app-alert>

    <div class="w-100 mt-0"></div>

    <div class="col-12 px-0">
      <app-crud-form-footer
        [id]="dataId()"
        [created]="created"
        [disable]="readonly"
        [loading]="loadingService.getLoading()"
        (cancel)="cancel()"
      ></app-crud-form-footer>
    </div>
  </form>
</div>
