import { Component, inject, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, forkJoin } from 'rxjs';
import { DateTime } from 'luxon';

import { FormComponent } from '@shared/components';
import { PdfFile } from '@admin/models/PdfFile.model';

@Component({
  selector: 'app-crud-form',
  standalone: true,
  imports: [],
  template: '',
})
export class CrudFormComponent extends FormComponent {
  route = inject(ActivatedRoute);
  router = inject(Router);

  created: boolean = false;
  dataId = signal(-1);
  eventId = signal(this.route.parent?.snapshot.paramMap.get('eventid') ?? -1);
  group = signal('');
  initialized: boolean = false;
  services: Observable<any>[] = [];
  readonly: boolean = false;

  pdfFiles: PdfFile[] = [];
  currentTemplate = {
    name: '',
    file: '',
  };

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const eventId = this.route.parent?.snapshot.paramMap.get('eventid');
      if (this.dataId() === -1) {
        this.dataId.set(Number(params.get('id') ?? -1));
      }
      this.eventId.set(Number(eventId ?? -1));

      const parts = this.router.url.split('/');

      if (!this.endpoint) {
        this.endpoint = (
          this.dataId() !== -1 ? parts.slice(2, -2) : parts.slice(2, -1)
        ).join('/');
        this.group.set(this.endpoint.replace(/\//g, '.'));
      }

      if (this.dataId() !== -1) {
        this.method = 'put';
        this.endpoint = `${this.endpoint}/${this.dataId()}`;
        this.formatEndpoint();
        this.services.push(this.api.call(this.endpoint));
      }
    });

    this.load();
  }

  // ! USED TO FORMAT A CRUD ENDPOINT AFTER CREATE IT
  formatEndpoint() {}

  getServices() {
    return this.services;
  }

  load() {
    const services = !this.initialized
      ? [...this.services]
      : [...this.getServices()];

    if (services.length === 0) return;

    this.startLoading();

    forkJoin(services).subscribe({
      next: (responses) => {
        this.handleLoadResponse(responses);
        this.initialized = true;
        this.stopLoading();
      },
      error: (err) => {
        this.handleErrors(err);
        this.stopLoading();
      },
    });
  }

  handleLoadResponse(responses: any[]) {}

  handleFile() {}

  handleErrors(errors: any[]) {}

  cancel() {
    this.router.navigate([
      this.router.url
        .split('/')
        .splice(
          0,
          this.router.url.split('/').length - (this.dataId() !== -1 ? 2 : 1)
        )
        .join('/'),
    ]);
  }

  // Date format: YYYY-MM-dd, time format hh:mm:ss
  getZeroDate(date: Date, time: string): string {
    const format = this.getFormattedDate(date) + ' ' + time;

    return new Date(format).toISOString();
  }

  getFormattedDate(date: Date): string {
    return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
  }

  override getParams() {
    return this.data.value;
  }

  override isValid(): boolean {
    console.log(this.data);
    console.log(this.data.value);
    console.log(this.data.valid);
    
    const valid: boolean = this.data.valid ?? false;

    if (!valid) {
      this.handleAlert('¡Llena todos los campos requeridos!');
    }

    return valid;
  }

  selectFile(file: Event) {
    if (this.loadingService.getLoading()) return;

    this.startLoading();

    const target = file.target as HTMLInputElement;

    if (target) {
      let template = { name: '', file: '' };
      const uploadedFile = (target.files as FileList)[0];

      if (!uploadedFile.name) {
        this.loadingService.stop();
        return;
      }

      // Max 3mb
      const maxSizeInBytes = 3 * 1024 * 1024;

      if (uploadedFile.size > maxSizeInBytes) {
        this.handleAlert('El archivo excede el tamaño permitido de 3 MB');
        this.loadingService.stop();
        return;
      }

      template.name = uploadedFile.name;

      let fileReader = new FileReader();

      if (target.files && target.files.length > 0) {
        let file = target.files[0];
        fileReader.readAsDataURL(file);

        fileReader.onload = (event: any) => {
          template.file = event.target.result;

          this.currentTemplate.name = template.name;
          this.currentTemplate.file = template.file;

          this.handleFile();
        };
      }
    }

    this.stopLoading();
  }

  selectDocument(file: any) {
    if (this.loadingService.getLoading()) return;

    this.startLoading();

    const target = file.target as HTMLInputElement;

    if (target) {
      const pdfFile: PdfFile = {
        id: -1,
        name: '',
        file: '',
      };
      const uploadedFile = (target.files as FileList)[0];

      if (!uploadedFile.name) {
        this.loadingService.stop();
        return;
      }

      // Max 3mb
      const maxSizeInBytes = 3 * 1024 * 1024;

      if (uploadedFile.size > maxSizeInBytes) {
        this.handleAlert('El archivo excede el tamaño permitido de 3 MB');
        this.loadingService.stop();
        return;
      }

      pdfFile.name = uploadedFile.name;

      let fileReader = new FileReader();

      if (target.files && target.files.length > 0) {
        let file = target.files[0];
        fileReader.readAsDataURL(file);

        fileReader.onload = (event: any) => {
          pdfFile.file = event.target.result;
          this.pdfFiles.push(pdfFile);

          const pdf = document.getElementById('pdf-selector');

          if (pdf) {
            (pdf as HTMLInputElement).value = '';
          }
        };
      }
    }

    this.stopLoading();
  }

  deletePdf(index: number) {
    const pdf = document.getElementById('pdf-selector');
    const pdfFile = this.pdfFiles[index];

    if (pdfFile && pdfFile.id !== -1 && pdfFile.file.includes('http')) {
      this.startLoading();

      this.api
        .call(`${this.endpoint}/document/${pdfFile.id}`, 'delete', {})
        .subscribe({
          next: (response) => {
            if (response.status === 200) {
              this.pdfFiles.splice(index, 1);

              if (pdf) {
                (pdf as HTMLInputElement).value = '';
              }

              this.handleAlert('Documento eliminado correctamente', 'success');
            } else {
              this.handleAlert(
                response.message.join(', ') ||
                  '¡Ocurrió un error al eliminar el documento!'
              );
            }

            this.stopLoading();
          },
          error: (error) => {
            console.error(error);
            this.handleAlert('¡Ocurrió un error al eliminar el documento!');
            this.stopLoading();
          },
        });
    } else {
      if (pdf) {
        (pdf as HTMLInputElement).value = '';
      }

      this.pdfFiles.splice(index, 1);
    }
  }

  override success(response: any): void {
    if (this.dataId() === -1) this.created = true;

    this.handleAlert(
      `¡${
        this.created ? 'Elemento creado' : 'Cambios guardados'
      } correctamente!`,
      'success'
    );
    this.stopLoading();
  }
}
