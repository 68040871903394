<div class="page-container">
  <div class="container min-container py-4">
    <h1 class="px-2">Mi perfil</h1>

    <form class="row" [formGroup]="data" (ngSubmit)="submit()">
      <div class="col-12 mb-3 mb-lg-4">
        <div class="avatar-container">
          <div class="avatar clickable" (click)="fileInput.click()">
            <img
              [src]="data.get('avatar')?.value ?? '/assets/svg/avatar.svg'"
              alt="Avatar"
              onerror="this.onerror=null; this.src='/assets/svg/avatar.svg'"
            />

            <i class="fa-solid fa-pen-circle fa-xl text-primary"></i>
          </div>

          <span class="name ps-5">
            <button
              type="button"
              class="btn btn-primary btn-amda-purple"
              (click)="fileInput.click()"
            >
              <div
                class="d-flex w-100 h-100 p-0 align-items-center justify-content-center"
              >
                MODIFICAR FOTO
              </div>
            </button>
          </span>
        </div>
      </div>

      <input
        #fileInput
        type="file"
        hidden
        (change)="selectFile($event)"
        accept="image/png, image/jpeg, .png, .jpg"
      />

      <div class="col-12 mb-3 mb-lg-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>CURP</mat-label>
          <input
            matInput
            formControlName="curp"
            [maxlength]="18"
            (input)="onCURPInput($event)"
            required
          />

          @if (data.get('curp')?.touched &&
          data.get('curp')?.hasError('required')) {
          <mat-error> El CURP es <strong>requerido</strong> </mat-error>
          }
        </mat-form-field>
      </div>

      <div class="col-12 mb-3 mb-lg-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Nombre</mat-label>
          <input matInput formControlName="name" required />

          @if (data.get('name')?.touched &&
          data.get('name')?.hasError('required')) {
          <mat-error> El Nombre es <strong>requerido</strong> </mat-error>
          }
        </mat-form-field>
      </div>

      <div class="col-12 mb-3 mb-lg-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Apellidos</mat-label>
          <input matInput formControlName="lastname" required />

          @if (data.get('lastname')?.touched &&
          data.get('lastname')?.hasError('required')) {
          <mat-error> Los Apellidos son <strong>requeridos</strong> </mat-error>
          }
        </mat-form-field>
      </div>

      <div class="col-12 mb-3 mb-lg-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Correo electrónico</mat-label>
          <input matInput type="email" formControlName="email" required />

          @if (data.get('email')?.touched &&
          data.get('email')?.hasError('required')) {
          <mat-error>
            El Correo electrónico es <strong>requerido</strong>
          </mat-error>
          }
        </mat-form-field>
      </div>

      <div class="col-12 my-4">
        <h5 class="mb-0">Datos de acceso</h5>
      </div>

      <div class="col-12 mb-3 mb-lg-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Nueva contraseña</mat-label>
          <input matInput type="password" formControlName="password" required />

          @if (data.get('password')?.invalid && (data.get('password')?.dirty ||
          data.get('password')?.touched)) { @if
          (data.get('password')?.errors?.['required']) {
          <mat-error> La Contraseña es <strong>requerida</strong> </mat-error>
          } @if (data.get('password')?.errors?.['minlength']) {
          <mat-error> La Contraseña es <strong>requerida</strong> </mat-error>
          } @if (data.get('password')?.errors?.['password']) {
          <mat-error>
            ¡La contraseña debe tener al menos una mayúscula, una minúscula y un
            número!
          </mat-error>
          } }
        </mat-form-field>
      </div>

      <div class="col-12 mb-3 mb-lg-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Confirmar contraseña</mat-label>
          <input matInput type="password" formControlName="confirm" required />

          @if (data.get('confirm')?.invalid && (data.get('confirm')?.dirty ||
          data.get('confirm')?.touched)) {
          @if(data.get('confirm')?.errors?.['required']) {
          <mat-error> La Contraseña es <strong>requerida</strong> </mat-error>
          } @if (data.get('confirm')?.errors?.['minlength']) {
          <mat-error> La Contraseña es <strong>requerida</strong> </mat-error>
          } @if (data.get('confirm')?.errors?.['password']) {
          <mat-error>
            ¡La contraseña debe tener al menos una mayúscula, una minúscula y un
            número!
          </mat-error>
          } }
        </mat-form-field>
      </div>

      <app-alert [content]="alertData"></app-alert>

      <div class="col-md-6 mx-auto mt-3">
        <button type="submit" class="btn btn-primary btn-outline-purple w-100">
          Actualizar <i class="fa-regular fa-arrow-right ms-2"></i>
        </button>
      </div>
    </form>
  </div>
</div>
