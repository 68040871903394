import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  Signal,
  computed,
  inject,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { RolesService } from '@services/roles.service';

@Component({
  selector: 'app-crud-header',
  standalone: true,
  imports: [CommonModule, RouterModule, MatIconModule, MatButtonModule],
  templateUrl: './crud-header.component.html',
  styleUrl: './crud-header.component.scss',
})
export class CrudHeaderComponent {
  @Input() title: string = '';
  @Input() createRoute: string = '';
  @Input() titleContainer: string = 'w-100';
  @Input() newA: boolean = false;

  group = signal('');
  private roles = inject(RolesService);
  protected route = inject(ActivatedRoute);
  protected router = inject(Router);

  canCreate: Signal<boolean> = computed(() =>
    this.createRoute ? this.roles.can(this.group(), 'create') : false
  );

  ngOnInit(): void {
    if (this.createRoute) {
      const parts = this.router.url.split('/');
      const eventId = this.route.parent?.snapshot.paramMap.get('eventid');

      if (eventId) {
        const endpoint = parts
          .join('/')
          .substring(Number(eventId) !== -1 ? 3 : 1);
        this.group.set(endpoint.replace('/', '.'));
      }
    }
  }
}
