import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-date-controller',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  providers: [],
  templateUrl: './date-controller.component.html',
  styleUrl: './date-controller.component.scss',
})
export class DateControllerComponent {
  @Input() hasEnd: boolean = true;
  @Input() hasStart: boolean = true;
  @Input() startDate: string | undefined | null = '';
  @Input() endDate: string | undefined | null = '';
  @Output() startDateChange: EventEmitter<string> = new EventEmitter();
  @Output() endDateChange: EventEmitter<string> = new EventEmitter();

  localStart: any = null;
  localEnd: any = null;

  data = this.formBuilder.group({
    start_date: new FormControl<Date | null>(null),
    start_time: [''],
    end_date: new FormControl<Date | null>(null),
    end_time: [''],
  });
  date: any;

  constructor(protected formBuilder: FormBuilder) {}

  ngOnInit(): void {
    if (this.startDate && typeof this.startDate === 'string') {
      const startParts = this.startDate.split(' ');
      const times = startParts[0].split('-');
      const date = new Date(
        Number(times[0]),
        Number(times[1]),
        Number(times[2])
      );

      date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

      this.data.get('start_date')?.setValue(date);
      this.data.get('start_time')?.setValue(startParts[1]);
      this.localStart = new Date(this.startDate);
    }

    if (this.endDate && typeof this.endDate === 'string') {
      const endParts = this.endDate.split(' ');

      this.data.get('end_date')?.setValue(new Date(endParts[0]));
      this.data.get('end_time')?.setValue(endParts[1]);

      this.localEnd = new Date(this.endDate);
    }
  }

  handleStart(event: any) {
    const date = new Date(event);

    this.localStart = new Date(event);
    this.localStart.setMinutes(date.getMinutes() - date.getTimezoneOffset());

    this.data.get('start_date')?.setValue(date);
    this.alertDateStart();
  }

  alertDateStart() {
    let startString = '';

    if (this.localStart && this.data.get('start_time')?.value) {
      startString = `${this.getFormattedDate(
        this.localStart
      )} ${this.getFormattedTime(this.data.get('start_time')?.value ?? '')}`;
    }

    this.startDateChange.emit(startString);
  }

  handleYear(event: any) {
    const date = new Date(event);

    this.localStart = new Date(event);
    this.localStart.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    this.data.get('end_date')?.setValue(date);
    this.alertDateStart();
  }

  handleEnd(event: any) {
    const date = new Date(event);

    this.localEnd = new Date(event);
    this.localEnd.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    this.data.get('end_date')?.setValue(date);
    this.alertDateEnd();
  }

  alertDateEnd() {
    let endString = '';

    if (this.localEnd && this.data.get('end_time')?.value) {
      endString = `${this.getFormattedDate(
        this.localEnd
      )} ${this.getFormattedTime(this.data.get('end_time')?.value ?? '')}`;
    }

    this.endDateChange.emit(endString);
  }

  getFormattedDate(date: Date): string {
    return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
  }

  getFormattedTime(time: string): string {
    const parts = time.split(':');

    if (parts.length < 3) {
      return time + ':00';
    }

    return time;
  }
}
