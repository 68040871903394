import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';

import {
  CrudFormFooterComponent,
  CrudFormHeaderComponent,
  CrudFormComponent,
  ImageControllerComponent,
} from '@admin/components';

import { AlertComponent } from '@shared/components/alert/alert.component';

@Component({
  selector: 'app-users-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,

    AlertComponent,
    CrudFormHeaderComponent,
    CrudFormFooterComponent,
    ImageControllerComponent,
  ],
  templateUrl: './users-form.component.html',
  styleUrl: './users-form.component.scss',
})
export class UsersFormComponent extends CrudFormComponent {
  resources: string[] = [];
  types: string[] = [];

  override data = this.formBuilder.group({
    name: ['', [Validators.required]],
    lastname: ['', [Validators.required]],
    email: ['', [Validators.required]],
    avatar: [''],
    password: [''],
    confirm: [''],
  });

  override handleLoadResponse(responses: any[]): void {
    if (this.dataId() !== -1 && responses[0] && responses[0].status === 200) {
      const responseData = responses[0].data;
      this.data.patchValue(responseData);

      if (
        this.data.get('avatar')?.value &&
        typeof this.data.get('avatar')?.value === 'string'
      ) {
        this.currentTemplate.file = String(
          this.data.get('avatar')?.value ?? ''
        );
      }
    }

    if (this.readonly) this.data.disable();
  }

  override getParams() {
    const params = this.data.value;

    return params;
  }

  override handleFile() {
    if (this.currentTemplate.file) {
      this.data.get('avatar')?.setValue(this.currentTemplate.file);
    }
  }
}
