import { CommonModule } from '@angular/common';
import { Component, Input, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { RoutePermissionsPipe } from '@shared/pipes/route-permissions.pipe';
import { MenuItem } from '@admin/models/MenuItem.model';
import { EventModel } from '@admin/models/Event.model';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NgbCollapseModule,
    MatButtonModule,
    MatIconModule,
    RoutePermissionsPipe,
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {
  @Input() items: MenuItem[] = [];
  @Input() eventId = signal(-1);
  @Input() event: EventModel = new EventModel();

  checkHavePermission: boolean = true;

  logout() {}
}
