import { CrudComponent } from '@admin/components/crud/crud.component';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';

import { AlertComponent } from '@shared/components/alert/alert.component';
import { ElementModel } from '@shared/models/Element.model';
import { Pagination } from '@shared/models/Pagination.model';

import { SearchBarComponent } from '@admin/components/search-bar/search-bar.component';
import { State } from '@admin/models/State.model';
import { Tag } from '@admin/models/Tag.model';
import { CrudHeaderComponent } from '@admin/components/crud-header/crud-header.component';

@Component({
  selector: 'app-tags',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatPaginatorModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    AlertComponent,
    CrudHeaderComponent,
    SearchBarComponent,
  ],
  templateUrl: '../../components/crud/crud.component.html',
  // templateUrl: './tags.component.html',
  styleUrl: './tags.component.scss',
})
export class TagsComponent extends CrudComponent {
  override title: string = 'Tags';
  override data: Pagination<Tag> = new Pagination(Tag);
  override filters: ElementModel[] = [
    { label: 'Activo', value: '1' },
    { label: 'Inactivo', value: '0' },
  ];

  override handleLoadResponse(responses: any[]): void {
    if (responses[0].data) this.data.setData(responses[0].data);

    this.pagination.set(this.data.rows.length > 0);
    this.stopLoading();
  }
}
