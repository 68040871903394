import { Section } from '@admin/models/Event.model';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DateControllerComponent } from '../date-controller/date-controller.component';

@Component({
  selector: 'app-event-section',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgbCollapseModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    NgSelectModule,
    DateControllerComponent,
  ],
  templateUrl: './event-section.component.html',
  styleUrl: './event-section.component.scss',
})
export class EventSectionComponent {
  @Output() onDelete = new EventEmitter();
  @Input() section: Section = new Section();
  @Input() fonts: string[] = [];
  @Input() sections: string[] = [];
  hasDates: boolean = false;

  ngOnInit(): void {
    if (this.section.start_at || this.section.end_at) {
      this.hasDates = true;
    }
  }

  handleDate(event: string, end: boolean = false) {
    if (end) {
      this.section.end_at = event;
    } else {
      this.section.start_at = event;
    }
  }

  delete() {
    this.onDelete.emit();
  }
}
