import { Main } from '@shared/models/Main.model';
import { PdfFile } from './PdfFile.model';

export class Speaker extends Main {
  id: number = -1;
  name: string = '';
  description: string = '';
  documents: PdfFile[] = [];
  email: string = '';
  phone: string = '';
  location: string = '';
  image: string = '';
  event_id: number = 0;
  enterprise_id: number = 0;
  active: number = 0;
}
