import { Main } from '@shared/models/Main.model';

export class Enterprise extends Main {
  id: number = -1;
  name: string = '';
  image: string = '';
  address: string = '';
  phone: string = '';
  active: number = 0;
}
